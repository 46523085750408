import { invoke } from "@tauri-apps/api/core";
import { AGPositionId } from "./redux/slices/vscsSlice";

export type SquelchXmtrPayload = {
  btnId: AGPositionId;
  // rcag id
  id: string;
  rx: boolean;
  tx: boolean;
}

export type SetupXmtrPayload = {
  btnId: AGPositionId;
  // rcag id
  id: string;
  lon: number;
  lat: number;
  elevation: number;
  vhfFreq: number;
  uhfFreq: number;
  rx: number;
  tx: number;
};

export type SetXmtrParamsPayload = {
  btnId: AGPositionId;
  // rcag id
  id: string;
  rx: number;
  tx: number;
}

export function setupXmtr(payload: SetupXmtrPayload) {
  return invoke("setup_xmtr", { payload });
}

export function setXmtrState(payload: SetXmtrParamsPayload) {
  return invoke("set_xmtr_state", { payload });
}

export function removeXmtr(btnId: AGPositionId) {
  return invoke("remove_xmtr", { btnId });
}
