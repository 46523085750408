import { Type } from "@feathersjs/typebox";
import { ArtccId } from "../events.js";

export type FavId = string;
export type ArtccFavId = `${ArtccId}-${FavId}`;
export type FavModuleId = number;
export type ArtccFavModuleId = `${ArtccId}-${FavId}-${FavModuleId}`;

export type FavModuleIdentifierRecord = {
  artccId: ArtccId;
  favId: FavId;
  moduleId: FavModuleId;
};

export const FavIdSchema = Type.String();
