import { BitmapText, Container, Graphics } from "@pixi/react";
import { AGPositionId } from "~/redux/slices/vscsSlice";
import { AuxComponent } from "./AuxMessageArea";
import { AlternateFunctionButton } from "./FunctionButton";
import { buttonHeight, functionButtonXMapList, functionButtonWidth, gapSize, vscsColorNameMap, fontHeight, fontName } from "~/constants";
import { freqAreaHeight } from "./FreqArea";

const ManRingButton = ({ x = 0, y = 0 }) => {
  return (
    <Container
    x={x}
    y={y + freqAreaHeight}
    eventMode="static"
    >
      <Graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.beginFill(vscsColorNameMap.grey)
            .drawRect(0, 0, functionButtonWidth, buttonHeight)
            .endFill();
          graphics.lineStyle(3, vscsColorNameMap.black);
          graphics.drawRect(6, 6, functionButtonWidth - 12, buttonHeight - 12)
            .endFill();
        }}
      />
      <BitmapText
        x={functionButtonWidth / 2}
        y={fontHeight + 4}
        text={"MAN\nRING"}
        anchor={[0.5, 0]}
        style={{ fontName, tint: vscsColorNameMap.black }}
      />
    </Container>
  );
}

type GroundGroundPageProps = {
  page: 0 | 1;
};

export const GroundGroundPage = ({ page }: GroundGroundPageProps) => {

  const it = Array.from(
    { length: 25 },
    (_, i) => i + page * 25,
  ) as AGPositionId[];

  return <Container>
    {it.map((id, index) => {
      const x = functionButtonXMapList[index % 9];
      const y = (buttonHeight + gapSize) * Math.floor(index / 9);
      return <AlternateFunctionButton key={id} x={x} y={y} bgTint={vscsColorNameMap.grey} text="" />
    })}
    <ManRingButton x={functionButtonXMapList[7]} y={(buttonHeight + gapSize) * 2} />
    <AlternateFunctionButton key="PROG_CONF" x={functionButtonXMapList[8]} y={(buttonHeight + gapSize) * 2} text={"PROG\nCONF"} />
    <AlternateFunctionButton key="CALL_ANS" x={functionButtonXMapList[6]} y={(buttonHeight + gapSize) * 3} text={"CALL\nANS"} />
    <AlternateFunctionButton key="HOLD" x={functionButtonXMapList[6]} y={gapSize + (buttonHeight + gapSize) * 4} text={"HOLD"} />
    <AuxComponent />
  </Container>
}
