import { BitmapText, Container, Graphics } from "@pixi/react";
import { ColorSource, Graphics as PixiGraphics } from "pixi.js";
import React, { useEffect, useRef } from "react";
import { vscsColorNameMap } from "~/constants";
import { usePressedListener } from "~/hooks/usePressedListener";
import { useRootDispatch, useRootSelector } from "~/redux/hooks";
import { selectVikInput, selectVikReady, selectVikStatus, setVikReady } from "~/redux/slices/vscsSlice";
import { updateVikInputThunk } from "~/redux/updateVikInputThunk";
import { horizFreqs, toneManager, vertFreqs } from "~/tones";

const vikArrow = `\u0400` as const;
const vikInit = `\u0401` as const;
const vikRls = `\u0402` as const;

type VikButtonIconContent = typeof vikArrow | typeof vikInit | typeof vikRls;

const vikHash = `#`;
const vikAsterisk = `*`;

const vikBrightDisp = `\u0200`;
const vikBrightKey = `\u0201`;

const vikIconFontName = "vikIcons";
const vikBrightFontName = "vikBright";
const vikNumpadFontName = "vikNumpad";
const vikNumDisplayFontName = "vikNumDisplay";

export const vikWidth = 340;
const spacing = 28;
const size = 53;

type DialButtonProps = {
  x?: number;
  y?: number;
  idx: number;
  ready: boolean;
  onpointerdown: () => void;
};

const dialpadTextList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", vikHash, "0", vikAsterisk];

const DialButton = ({ x = 0, y = 0, idx, ready, onpointerdown }: DialButtonProps) => {
  const ref = useRef<PixiGraphics>(null);
  const pressed = usePressedListener(ref, false, !ready);
  
  useEffect(() => {
    const xIdx = idx % 3;
    const yIdx = Math.floor(idx / 3);
    const freqs = [vertFreqs[xIdx]!, horizFreqs[yIdx]!];
    if (pressed) {
      toneManager.startTone(freqs);
    } else {
      toneManager.stopTone();
    }
  }, [pressed, idx]);

  const text = dialpadTextList[idx]!;

  return (
    <Container x={x} y={y} eventMode={ready ? "static" : "none"}>
      <Graphics
        ref={ref}
        eventMode="static"
        onpointerdown={onpointerdown}
        draw={(graphics) => {
          graphics.clear();
          graphics.beginFill(0xffffff)
            .drawRect(0, 0, size, size)
            .endFill();
        }}
      />
      <BitmapText eventMode="none" text={text} style={{ tint: 0, fontName: vikNumpadFontName }} />
    </Container>
  );
};

type VikButtonProps = {
  x?: number;
  y?: number;
  content: VikButtonIconContent;
  bgColor: ColorSource;
  onpointerdown?: () => void;
};

const VikButton = ({ x = 0, y = 0, content, onpointerdown, bgColor }: VikButtonProps) => {
  return (
    <Container x={x} y={y}>
      <Graphics
        eventMode="static"
        onpointerdown={onpointerdown}
        draw={(graphics) => {
          graphics.clear();
          graphics.beginFill(bgColor)
            .drawRect(0, 0, 82, 82)
            .endFill();
        }}
      />
      <BitmapText eventMode="none" text={content} style={{ tint: 0, fontName: vikIconFontName }} />
    </Container>
  );
};

export const Vik = ({ x = 0, y = 0 }) => {
  const dispatch = useRootDispatch();
  const vikReady = useRootSelector(selectVikReady);
  const status = useRootSelector(selectVikStatus);
  const input = useRootSelector(selectVikInput);

  const statusText = status?.replaceAll("_", " ") ?? "";

  return (
    <Container x={x} y={y}>
      <Graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.beginFill(0x404040)
            .drawRect(0, 0, vikWidth, 600)
            .endFill();
        }}
      />
      <Container y={26}>
        <Graphics
          draw={(graphics) => {
            graphics.clear();
            graphics.beginFill(0)
              .drawRect(0, 0, 340, 94)
              .endFill();
          }}
        />
        <BitmapText x={18} y={18} text={statusText} style={{ tint: 0xff6800, fontName: vikNumDisplayFontName }} />
        <BitmapText x={18} y={56} text={input} style={{ tint: 0xff6800, fontName: vikNumDisplayFontName }} />
      </Container>
      <VikButton 
        x={24}
        y={133}
        content={vikArrow}
        bgColor={vscsColorNameMap.yellow}
        onpointerdown={() => dispatch(updateVikInputThunk(input.slice(0, -1)))}
      />
      <VikButton 
        x={130}
        y={133}
        content={vikInit}
        bgColor={vscsColorNameMap.green}
        onpointerdown={() => dispatch(setVikReady(true))}
      />
      <VikButton 
        x={236}
        y={133}
        content={vikRls}
        bgColor={vscsColorNameMap.red}
        onpointerdown={() => dispatch(setVikReady(false))}
      />

      <Container y={256}>
        {Array.from({ length: 12 }, (_, i) => {
          const xIdx = i % 3;
          const yIdx = Math.floor(i / 3);
          const x = 24 + (size + spacing) * xIdx;
          const y = (size + spacing) * yIdx;
          const text = dialpadTextList[i];
          return (
            <DialButton
              key={i}
              ready={status !== null}
              x={x}
              y={y}
              idx={i}
              onpointerdown={() => {
                if (vikReady) {
                  dispatch(updateVikInputThunk(input + text));
                }
              }}
            />
          );
        })}
        <Graphics
          x={263}
          draw={(graphics) => {
            graphics.clear();
            graphics.beginFill(0xffffff)
              .drawRect(0, 0, 53, 134)
              .endFill();

            graphics.beginFill(0xffffff)
              .drawRect(0, 162, 53, 134)
              .endFill();
          }}
        />
        <BitmapText x={263} y={0} text={vikBrightDisp} style={{ tint: 0, fontName: vikBrightFontName }} />
        <BitmapText x={263} y={162} text={vikBrightKey} style={{ tint: 0, fontName: vikBrightFontName }} />
      </Container>
    </Container>
  );
};
