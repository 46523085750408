import type { FlightplanId } from "../flightplanData/flightplan.js";
import type {
  Digit,
  Letter,
  Nullable,
  RequireKeys,
  ValueFn,
} from "../utils/utility-types.js";
import { digits, letters } from "../utils/utility-types.js";
import {
  type AircraftPerformanceCategory,
  type FdpsFlightStatus,
  type WakeTurbCategory,
  type EquipmentQualifier,
  type AssignedAltitude,
  type NASAirspeed,
  type Time,
  type EtaTimeType,
  type EtdTimeType,
  type FlightRule,
  aircraftPerformanceCategories,
  equipmentQualifiers,
  etaTimeTypes,
  etdTimeTypes,
  fdpsFlightStatusList,
  flightRules,
  wakeTurbCategories,
  NasAssignedAltitudeSchema,
} from "../flightplanData/nasTypes.js";
import {
  communicationCodes,
  datalinkCodes,
  navigationCodes,
  pbnCodes,
  surveillanceCodes,
  type CommunicationCode,
  type DatalinkCode,
  type NavigationCode,
  type PbnCode,
  type SurveillanceCode,
} from "../flightplanData/equipment.js";
import { formatUtcTime, isString, unsafeKeys } from "../utils/utility-functions.js";
import { formatRoute } from "../utils/formatRoute.js";
import { StringEnum, TSchema, Type } from "@feathersjs/typebox";
import { NullableSchema } from "../typebox.js";
import { NasAppliedAdaptedRouteRecord, NasAppliedAdaptedRouteSchema } from "./nasAdaptedRoute.js";
import { TfmReroute, TfmRerouteSchema } from "./tmi.js";

export type CidLetter = Exclude<Letter, "I" | "O">;
export const cidLetters = letters.filter(
  (l): l is CidLetter => l !== "I" && l !== "O",
);
export const cidCharacters = [...cidLetters, ...digits] as const;
export type ComputerIdCharacter = (typeof cidCharacters)[number];
export type ComputerId = `${Digit}${Digit}${ComputerIdCharacter}` | `${Digit}${ComputerIdCharacter}${Digit}`;

export type CoordinationTimeHandling = "D" | "E" | "P";
export type CoordinationTime = {
  type: CoordinationTimeHandling;
  time: number;
};

export const activeCoordinationTimeTypes: CoordinationTimeHandling[] = ["D", "E"];

export type FAARoute = string;
export type ICAORoute = string;
export type AircraftType = string;

export class EramFlightplan {
  constructor(
    id: FlightplanId,
    public isDroneFp = false,
    extraFields: Partial<
      Omit<
        EramFlightplan,
        "id" | "flightRef" | "isDroneFp" | "dateCreated" | "dateUpdated"
      >
    > = {},
  ) {
    this.id = id.toUpperCase();
    Object.assign(this, extraFields);
    const now = Date.now();
    this.dateCreated = now;
    this.dateUpdated = now;
  }

  id: FlightplanId;

  isEmpty = true;

  flightRef: Nullable<string> = null;

  cid: Nullable<ComputerId> = null;

  callsign = "";

  squawk: Nullable<string> = null;

  reassignedSquawk: Nullable<string> = null;

  actualSpeed: Nullable<NASAirspeed> = null;

  dateCreated: number;

  dateUpdated: number;

  aircraftType: AircraftType = "";

  aircraftAddress: Nullable<number> = null;

  alternate: string[] = [];

  departure = "ZZZZ";

  destination = "ZZZZ";

  coordinationFix: Nullable<string> = null;

  coordinationTime: Nullable<CoordinationTime> = null;

  equipmentQualifier: Nullable<EquipmentQualifier> = null;

  flightrules: FlightRule = "IFR";

  flighttype = "GENERAL";

  requestedAirspeed: Nullable<NASAirspeed> = null;

  requestedAltitude: Nullable<number> = null;

  reportedAltitude: Nullable<number> = null;

  assignedAltitude: Nullable<AssignedAltitude> = null;

  numOfAircraft = 1;

  persons = 1;

  remarks = "";

  localRemarks = "";

  // route in FAA format
  route: FAARoute = "";

  routeIsTruncated = false;

  previousRoute: Nullable<string> = null;

  appliedRoutes: NasAppliedAdaptedRouteRecord[] = [];

  tfmReroute: Nullable<TfmReroute> = null;

  status: FdpsFlightStatus = "PROPOSED";

  performanceCat: AircraftPerformanceCategory = "C";

  waketurbcategory: WakeTurbCategory = "L";

  communicationCodes: CommunicationCode[] = [];

  dataLinkCodes: DatalinkCode[] = [];

  otherDatalinkCapabilities: string[] = [];

  navigationCodes: NavigationCode[] = [];

  pbnCodes: PbnCode[] = [];

  otherNavigationCapabilities: string[] = [];

  surveillanceCodes: SurveillanceCode[] = [];

  otherSurveillanceCapabilities: string[] = [];

  selectiveCallingCode = "";

  operator = "";

  other = "";

  registration = "";

  // Initial gate time of departure
  igtd: Nullable<number> = null;

  // Estimated time of arrival
  eta: Nullable<Time<EtaTimeType>> = null;

  landingTime: Nullable<number> = null;

  sta: Nullable<number> = null;

  std: Nullable<number> = null;

  // Estimated time of departure
  etd: Nullable<Time<EtdTimeType>> = null;

  static isRvsmEquipped(fp: EramFlightplan) {
    return fp.navigationCodes.includes("W");
  }

  static isSatCommEquipped(fp: EramFlightplan) {
    return fp.dataLinkCodes.some((c) => ["J5", "J7"].includes(c));
  }

  static isVFR(fp: EramFlightplan) {
    return (
      fp.assignedAltitude &&
      Object.entries(fp.assignedAltitude).some(
        ([k, v]) => k.startsWith("vfr") && v,
      )
    );
  }

  /**
   * mutates the input flightplan
   * returns the updated fields
   * @param fp
   * @param d
   */
  static update(
    fp: EramFlightplan,
    d: Partial<Omit<EramFlightplan, "id">> | ValueFn<EramFlightplan>,
  ) {
    const changedFields: (keyof EramFlightplan)[] = ["dateUpdated"];
    const prevRoute = fp.route;
    if (typeof d === "function") {
      const newFp = d(fp);
      changedFields.push(
        ...unsafeKeys(newFp).filter((k) => newFp[k] !== fp[k]),
      );
      Object.assign(fp, newFp);
    } else {
      changedFields.push(...(Object.keys(d) as (keyof EramFlightplan)[]));
      Object.assign(fp, d);
    }
    if ("route" in changedFields) {
      fp.previousRoute = prevRoute;
      changedFields.push("previousRoute");
    }
    fp.isEmpty = false;
    fp.dateUpdated = Date.now();
    return Object.fromEntries(
      changedFields.map((k) => [k, fp[k]]),
    ) as Partial<EramFlightplan>;
  }

  static createFromPosconFlightplan(fp: PosconFlightplan) {
    const eramFp = new EramFlightplan(fp.id);
    eramFp.callsign = fp.fp_callsign ?? "";
    eramFp.aircraftType = fp.fp_aircrafttype ?? "";
    eramFp.alternate = [fp.fp_alternate, fp.fp_alternate2].filter(isString);
    eramFp.departure = fp.fp_departure ?? "";
    eramFp.destination = fp.fp_destination ?? "";
    eramFp.route = fp.fp_route ? formatRoute(fp.fp_route) : "";
    eramFp.equipmentQualifier = fp.fp_faaterminalequipmentsuffix ?? null;
    eramFp.numOfAircraft = fp.fp_numOfAircraft ?? 1;
    eramFp.persons = Number(fp.fp_persons ?? "1");
    eramFp.remarks = fp.fp_remarks ?? "";
    eramFp.pbnCodes = (fp.fp_pbn?.split(",") as PbnCode[]) ?? [];
    eramFp.performanceCat = fp.fp_performanceCat ?? "C";
    eramFp.waketurbcategory = fp.fp_waketurbcategory ?? "L";
    eramFp.sta = fp.date_sta ? new Date(fp.date_sta).getTime() : null;
    eramFp.std = fp.date_std ? new Date(fp.date_std).getTime() : null;
    if (fp.fp_level && /^[AF]\d+$/.test(fp.fp_level)) {
      eramFp.requestedAltitude = Number.parseInt(fp.fp_level.slice(1), 10);
      eramFp.assignedAltitude = {
        simple: Number.parseInt(fp.fp_level.slice(1), 10),
      };
    }
    return eramFp;
  }

  static createPosconFlightplan(fp: EramFlightplan): PosconFlightplan {
    return {
      id: fp.id,
      active: true,
      date_created: new Date(fp.dateCreated).toJSON(),
      date_updated: new Date(fp.dateCreated).toJSON(),
      fp_callsign: fp.callsign,
      fp_aircrafttype: fp.aircraftType,
      fp_alternate: fp.alternate[0],
      fp_alternate2: fp.alternate[1],
      fp_departure: fp.departure,
      fp_destination: fp.destination,
      fp_route: fp.route,
      fp_faaterminalequipmentsuffix: fp.equipmentQualifier ?? undefined,
      fp_numOfAircraft: fp.numOfAircraft,
      fp_persons: fp.persons.toString(),
      fp_remarks: fp.remarks,
      fp_pbn: fp.pbnCodes.join(","),
      fp_performanceCat: fp.performanceCat,
      fp_waketurbcategory: fp.waketurbcategory,
      date_sta: fp.sta ? new Date(fp.sta).toJSON() : undefined,
      date_std: fp.std ? new Date(fp.std).toJSON() : undefined,
      fp_level: fp.assignedAltitude?.simple
        ? `F${fp.assignedAltitude.simple}`
        : undefined,
    };
  }

  static schema = Type.Object({
    id: Type.String(),
    isDroneFp: Type.Boolean(),
    isEmpty: Type.Boolean({ description: "true if the flightplan has been manually created with the VP command and no amendments were made" }),
    flightRef: NullableSchema(Type.Number()),
    cid: NullableSchema(Type.String()),
    callsign: Type.String(),
    squawk: NullableSchema(Type.String()),
    reassignedSquawk: NullableSchema(Type.String()),
    assignedAltitude: NasAssignedAltitudeSchema,
    aircraftType: Type.String(),
    aircraftAddress: NullableSchema(Type.Number()),
    alternate: Type.Array(Type.String()),
    departure: Type.String(),
    destination: Type.String(),
    coordinationFix: NullableSchema(Type.String()),
    coordinationTime: Type.Object({
      type: StringEnum(["D", "E", "P"]),
      time: Type.Number(),
    }),
    equipmentQualifier: StringEnum(equipmentQualifiers),
    flightrules: StringEnum(flightRules),
    flighttype: Type.String(),
    requestedAirspeed: createUomSchema(["KNOTS", "MACH"], Type.Number(), true),
    actualSpeed: createUomSchema(["KNOTS", "MACH"], Type.Number(), true),
    requestedAltitude: NullableSchema(Type.Integer()),
    reportedAltitude: NullableSchema(Type.Integer()),
    numOfAircraft: Type.Integer(),
    persons: Type.Integer(),
    remarks: Type.String(),
    localRemarks: Type.String(),
    route: Type.String({ description: "route in FAA format" }),
    routeIsTruncated: Type.Boolean(),
    previousRoute: NullableSchema(Type.String()),
    appliedRoutes: Type.Array(NasAppliedAdaptedRouteSchema),
    tfmReroute: NullableSchema(TfmRerouteSchema),
    status: Type.Array(StringEnum(fdpsFlightStatusList)),
    performanceCat: StringEnum(aircraftPerformanceCategories),
    waketurbcategory: StringEnum(wakeTurbCategories),
    communicationCodes: Type.Array(StringEnum(communicationCodes)),
    dataLinkCodes: Type.Array(StringEnum(datalinkCodes)),
    otherDatalinkCapabilities: Type.Array(Type.String()),
    navigationCodes: Type.Array(StringEnum(navigationCodes)),
    pbnCodes: Type.Array(StringEnum(pbnCodes)),
    otherNavigationCapabilities: Type.Array(Type.String()),
    surveillanceCodes: Type.Array(StringEnum(surveillanceCodes)),
    otherSurveillanceCapabilities: Type.Array(Type.String()),
    selectiveCallingCode: Type.String(),
    operator: Type.String(),
    other: Type.String(),
    registration: Type.String(),
    igtd: NullableSchema(Type.Number()),
    landingTime: NullableSchema(Type.Number()),
    sta: NullableSchema(Type.Number()),
    std: NullableSchema(Type.Number()),
    eta: Type.Object({ timeType: StringEnum(etaTimeTypes), value: Type.Number() }),
    etd: Type.Object({ timeType: StringEnum(etdTimeTypes), value: Type.Number() }),
    dateCreated: Type.Number(),
    dateUpdated: Type.Number(),
  } satisfies Record<keyof EramFlightplan, TSchema>, { $id: "eramFlightplan" });
}

export type PartialFp = Partial<EramFlightplan>;

export type PartialFpWithId = RequireKeys<PartialFp, "id">;

export const excludedGlobalEramFlightplanFields = [
  "cid",
  "squawk",
  "reassignedSquawk",
  "status",
  "localRemarks",
] as const satisfies (keyof EramFlightplan)[];
export type ExcludedGlobalEramFlightplanField =
  (typeof excludedGlobalEramFlightplanFields)[number];

export type PosconFlightplan = {
  id: FlightplanId;
  active: boolean;
  date_ata?: string;
  date_atd?: string;
  date_created: string;
  date_sta?: string;
  date_std?: string;
  date_updated: string;
  fir?: string;
  fp_aircrafttype?: string;
  fp_alternate?: string;
  fp_alternate2?: string;
  fp_callsign?: string;
  fp_cruisespeed?: string;
  fp_dateofflight?: string;
  fp_departure?: string;
  fp_departuretime?: string;
  fp_destination?: string;
  fp_eet?: string;
  fp_endurance?: string;
  fp_equipmentcode?: string;
  fp_faaterminalequipmentsuffix?: EquipmentQualifier;
  fp_flightrules?: string;
  fp_flighttype?: string;
  fp_level?: string;
  fp_numOfAircraft?: number;
  fp_other?: string;
  fp_pbn?: string;
  fp_performanceCat?: AircraftPerformanceCategory;
  fp_persons?: string;
  fp_remarks?: string;
  fp_route?: string;
  fp_ssr?: string;
  fp_status?: string;
  fp_waketurbcategory?: WakeTurbCategory;
  fp_waterEquipment?: string;
  squawk?: number;
  // stuff I don't need
  fp_pilotname?: string;
  fp_virtual_operator?: string;
  ldapid?: string;
  operator?: string;
  pilot_id?: string;
  user_id?: string;
  version?: number;
};

export function formatSpeed(speed: NASAirspeed) {
  return `${speed.uom === "MACH" ? "M" : ""}${speed.value}`;
}

export function formatCoordinationTime(time: CoordinationTime) {
  return `${time.type}${formatUtcTime(time.time)}`;
}

function createUomSchema<Unit extends string>(units: Unit[], valueType: TSchema, nullable = false) {
  const TObj = Type.Object({
    uom: StringEnum(units),
    value: valueType,
  });
  return nullable ? NullableSchema(TObj) : TObj;
}
