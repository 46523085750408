export const anyFixExpr =
  /(?!C\d{4})(?!^NAT[A-Z]$)([A-Z]{2,5}|\d{4}[A-Z]|[A-Z]\d{4}|\d{2}[A-Z]{3}|K[A-Z]\d\d[A-Z])/m;
export const anyAirwayExpr =
  /([A-Z]\d\d[A-Z]|[A-Z]{1,2}\d+|[A-Z]{3}\d\d+|[A-Z]{2}\d\d[A-Z]|C\d{4}|NAT[A-Z])/m;

export const ROUTE_FORMAT_EXPRESSIONS = {
  airway: new RegExp(`^(${anyAirwayExpr.source})$`),
  sidStar: /^[A-Z]{3,6}\d[A-Z]?$/m,
  anyFix: new RegExp(`^(${anyFixExpr.source})$`),
  frd: new RegExp(`^(${anyFixExpr.source})(\\d{6})$`),
  latLonDeg: /^\d\d[NS]\d{3}[EW]$/m,
  latLonMin: /^\d{4}[NS]?\/\d{5}[EW]?$/m,
};

function cleanRoute(route: string) {
  const cleanedRoute = route
    .replaceAll(/\+|(\s?)DCT(\s?)|\/?N[0-9]{4}[FAM][0-9]{3,4}/g, " ")
    .replaceAll(/[.\s]+/g, " ");
  return cleanedRoute.trim();
}

export function segmentIsFix(segment: string) {
  return (
    ROUTE_FORMAT_EXPRESSIONS.anyFix.test(segment) ||
    ROUTE_FORMAT_EXPRESSIONS.frd.test(segment) ||
    ROUTE_FORMAT_EXPRESSIONS.latLonDeg.test(segment) ||
    ROUTE_FORMAT_EXPRESSIONS.latLonMin.test(segment)
  );
}

export function matchesAnyRouteSegment(segment: string) {
  return (
    ROUTE_FORMAT_EXPRESSIONS.airway.test(segment) ||
    ROUTE_FORMAT_EXPRESSIONS.sidStar.test(segment)
  );
}

export function formatRoute(
  route: string,
  dep = "",
  dest = "",
  isTruncated = false,
) {
  let formattedRoute = "";
  const routeSegments = cleanRoute(route).split(" ").filter(Boolean);
  let prevIsFix = true;
  let isFix = true;
  for (const segment of routeSegments) {
    isFix = segmentIsFix(segment);
    if (isFix && prevIsFix) {
      formattedRoute += `..${segment}`;
    } else {
      formattedRoute += `.${segment}`;
    }
    prevIsFix = isFix;
  }
  if (formattedRoute.length > 0 || (dest && dest !== "ZZZZ")) {
    formattedRoute += prevIsFix ? ".." : ".";
    if (isTruncated) {
      formattedRoute = formattedRoute.replace(/^\.+/m, "./.");
    }
  }
  if (dep !== "ZZZZ") {
    formattedRoute = dep + formattedRoute;
  }
  if (dest !== "ZZZZ") {
    formattedRoute += dest;
  }
  return formattedRoute;
}

export function formatIcaoRoute(route: string, dep = "", dest = "") {
  const faaRoute = formatRoute(route, dep, dest);
  return faaRoute
    .replaceAll(/^[./]+|\.+$/g, "")
    .replaceAll("..", " DCT ")
    .replaceAll(".", " ");
}
