import React, { createContext, useContext } from "react";
import { VscsPanel } from "~/redux/slices/vscsSlice";

const vscsPanelContext = createContext<VscsPanel | null>(null);

export const VscsPanelContextProvider = ({ panel, children }: { panel: VscsPanel, children: React.ReactNode }) => {
  return <vscsPanelContext.Provider value={panel}>{children}</vscsPanelContext.Provider>;
}

export const useVscsPanel = () => {
  const v = useContext(vscsPanelContext);
  if (v === null) {
    throw new Error("useVscsPanel must be used within a VscsPanelContextProvider.");
  }
  return v;
}
