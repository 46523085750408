import React from "react";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { freqAreaHeight } from "components/FreqArea";
import { buttonHeight, fontName, freqButtonGapSize, functionButtonWidth, gapSize, vscsColorNameMap } from "~/constants";
import { totalButtonHeight } from "./FrequencyButton";
import { StatusArea, statusAreaWidth } from "./StatusArea";

export const auxMessageAreaWidth = functionButtonWidth * 2 + freqButtonGapSize;
export const auxMsgAreaX = statusAreaWidth + freqButtonGapSize;

type AuxMessageAreaProps = {
  x?: number;
  y?: number;
};
export const AuxMessageArea = ({ x = 0, y = 0 }: AuxMessageAreaProps) => {
  return (
    <Container x={x} y={y}>
      <Graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.beginFill(vscsColorNameMap.cyan);
          graphics.drawRect(0, freqAreaHeight, auxMessageAreaWidth, buttonHeight);
          graphics.endFill();
          graphics.beginFill(vscsColorNameMap.white);
          graphics.drawRect(0, 0, auxMessageAreaWidth, freqAreaHeight);
          graphics.endFill();

          graphics.lineStyle(1, vscsColorNameMap.black);
          graphics
            .moveTo(7, freqAreaHeight)
            .lineTo(7, Math.floor(freqAreaHeight / 2))
            .lineTo(Math.floor(auxMessageAreaWidth / 4 - 5), Math.floor(freqAreaHeight / 2));

          graphics
            .moveTo(auxMessageAreaWidth - 7, freqAreaHeight)
            .lineTo(auxMessageAreaWidth - 7, Math.floor(freqAreaHeight / 2))
            .lineTo(Math.ceil((auxMessageAreaWidth * 3) / 4 + 5), Math.floor(freqAreaHeight / 2));
        }}
      />
      <BitmapText
        x={auxMessageAreaWidth / 2}
        text="R/T OFF"
        anchor={[0.5, 0]}
        style={{ fontName, tint: vscsColorNameMap.black }}
      />
      <BitmapText 
        y={freqAreaHeight}
        text={"CALLS FWD TO:\nH86"}
        style={{ fontName, tint: vscsColorNameMap.black }}
      />
    </Container>
  );
};

export const AuxComponent = ({ children }: { children?: React.ReactNode }) => {
  return (<Container x={0} y={totalButtonHeight * 4}>
    <StatusArea />
    <AuxMessageArea x={auxMsgAreaX} />
    <Container x={auxMsgAreaX + auxMessageAreaWidth + gapSize}>
      {children}
    </Container>
  </Container>);
}