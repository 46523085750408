import { Static, StringEnum, Type } from "@feathersjs/typebox";
import { artccIdSchema } from "../events.js";

export const adaptedRouteType = ["AAR", "ADAR", "ADR", "AIRWAY", "DP", "MILITARY", "STAR"] as const satisfies string[];
export const AdaptedRouteTypeSchema = StringEnum(adaptedRouteType);
export type AdaptedRouteType = (typeof adaptedRouteType)[number];

export type NasAdaptedRouteId = string;

export const NasAltitudeRangeSchema = Type.Object({
  upperAltitude: Type.Number(),
  lowerAltitude: Type.Number(),
});

export const NasAdaptedRouteSchema = Type.Object({
  owningArtccId: artccIdSchema,
  routeId: Type.String(),
  routeString: Type.String(),
  routeType: StringEnum(adaptedRouteType),
  altitudeRange: Type.Optional(NasAltitudeRangeSchema),
  aircraftCategories: Type.Array(Type.String()),
}, { $id: "nasAdaptedRoute" });

export const NasAdaptedDepartureRouteSchema = Type.Object({
  ...NasAdaptedRouteSchema.properties,
  departureAirports: Type.Array(Type.String()),
  adaptedFixes: Type.Array(Type.String()),
}, { $id: "nasAdaptedDepartureRoute" });

export const NasAdaptedArrivalRouteSchema = Type.Object({
  ...NasAdaptedRouteSchema.properties,
  arrivalAirports: Type.Array(Type.String()),
  adaptedFixes: Type.Array(Type.String()),
}, { $id: "nasAdaptedArrivalRoute" });

export const NasAdaptedDepartureArrivalRouteSchema = Type.Object({
  ...NasAdaptedRouteSchema.properties,
  departureAirports: Type.Array(Type.String()),
  arrivalAirports: Type.Array(Type.String()),
  adaptedFixes: Type.Array(Type.String()),
}, { $id: "nasAdaptedDepartureArrivalRoute" });

export const NasAppliedAdaptedRouteSchema = Type.Object({
  owningArtccId: artccIdSchema,
  routeId: Type.String(),
  routeType: AdaptedRouteTypeSchema,
  routeString: Type.String(),
  adaptedFix: Type.Optional(Type.String()),
  eligible: Type.Boolean(),
}, { $id: "nasAppliedAdaptedRoute" });

export type NasAdaptedDepartureRoute = Static<typeof NasAdaptedDepartureRouteSchema>;
export type NasAdaptedArrivalRoute = Static<typeof NasAdaptedArrivalRouteSchema>;
export type NasAdaptedDepartureArrivalRoute = Static<typeof NasAdaptedDepartureArrivalRouteSchema>;
export type NasAppliedAdaptedRouteRecord = Static<typeof NasAppliedAdaptedRouteSchema>;
