import { fontHeight, fontName, freqButtonGapSize, functionButtonWidth, vscsColorNameMap } from "~/constants";
import { BitmapText, Container, Graphics } from "@pixi/react";
import React from "react";

export const freqButtonWidth = freqButtonGapSize * 2 + functionButtonWidth * 3;
export const freqAreaHeight = fontHeight;

type FreqAreaProps = {
  freq: number;
  isEmergFreq?: boolean;
  highlight?: boolean;
};
export const FreqArea = ({ freq, isEmergFreq, highlight }: FreqAreaProps) => {
  const _color = isEmergFreq ? vscsColorNameMap.red : vscsColorNameMap.cyan;
  const color = highlight ? vscsColorNameMap.violet : _color;
  const _tint = isEmergFreq ? vscsColorNameMap.white : vscsColorNameMap.black;
  const tint = highlight ? vscsColorNameMap.white : _tint;
  return (
    <Container>
      <Graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.beginFill(color);
          graphics.drawRect(0, 0, freqButtonWidth, freqAreaHeight);
          graphics.endFill();
          graphics.lineStyle(1, tint);
          graphics
            .moveTo(7, freqAreaHeight)
            .lineTo(7, Math.floor(freqAreaHeight / 2))
            .lineTo(Math.floor(freqButtonWidth / 3) - 12, Math.floor(freqAreaHeight / 2));

          graphics
            .moveTo(freqButtonWidth - 7, freqAreaHeight)
            .lineTo(freqButtonWidth - 7, Math.floor(freqAreaHeight / 2))
            .lineTo(Math.ceil((freqButtonWidth * 2) / 3 + 12), Math.floor(freqAreaHeight / 2));
        }}
      />
      <BitmapText
        x={freqButtonWidth / 2}
        y={0}
        text={freq.toFixed(3)}
        anchor={[0.5, 0]}
        tint={tint}
        style={{ fontName, tint }}
      />
    </Container>
  );
};
