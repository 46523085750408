import type { ExactlyOne } from "../utils/utility-types.js";
import type { SectorId } from "../poscon/positions.js";
import { Type } from "@feathersjs/typebox";

export const fdpsFlightStatusList = [
  "ACTIVE",
  "DROPPED",
  "PROPOSED",
  "COMPLETED",
  "CANCELLED",
] as const satisfies string[];
export type FdpsFlightStatus = (typeof fdpsFlightStatusList)[number];

export const flightRules = ["IFR", "VFR", "SVFR", "DVFR"] as const satisfies string[];
export type FlightRule = (typeof flightRules)[number];
export function isFlightRule(s: string): s is FlightRule {
  return flightRules.includes(s as FlightRule);
}

export const equipmentQualifiers = [
  "L",
  "G",
  "U",
  "Z",
  "A",
  "I",
  "W",
  "R",
  "Q",
  "S",
  "X",
  "Y",
] as const satisfies string[];
export type EquipmentQualifier = (typeof equipmentQualifiers)[number];
export function isEquipmentQualifier(s: string): s is EquipmentQualifier {
  return equipmentQualifiers.includes(s as EquipmentQualifier);
}

export const aircraftPerformanceCategories = ["A", "B", "C", "D", "E", "F"] as const satisfies string[];
export type AircraftPerformanceCategory =
  (typeof aircraftPerformanceCategories)[number];
export function isAircraftPerformanceCategory(
  s: string,
): s is AircraftPerformanceCategory {
  return aircraftPerformanceCategories.includes(
    s as AircraftPerformanceCategory,
  );
}

export const wakeTurbCategories = ["M", "H", "L", "J"] as const satisfies string[];
export type WakeTurbCategory = (typeof wakeTurbCategories)[number];
export function isWakeTurbCategory(s: string): s is WakeTurbCategory {
  return wakeTurbCategories.includes(s as WakeTurbCategory);
}

export type RadialUom = "MAGNETIC" | "TRUE";
export type Radial = UomValue<"DEGREES", number, RadialUom>;

export type UomValue<
  UOM extends string,
  Value extends string | number = number,
  Ref extends string | never = never,
> = {
  uom: UOM;
  value: Value;
  ref?: Ref;
};

export type NASAirspeed = UomValue<NASAirspeedUom>;

export type NASAirspeedUom = "KNOTS" | "MACH";

export type DistanceUom = "NAUTICAL_MILES";

export type AltitudeUom = "FEET" | "METRES";

export type AltitudeRef = "MEAN_SEA_LEVEL" | "FLIGHT_LEVEL";

export type NasAltitude = UomValue<AltitudeUom, number, AltitudeRef>;

export type BlockAltitude<Altitude extends NasAltitude | number = number> = {
  min: Altitude;
  max: Altitude;
};

export const NasAssignedAltitudeSchema = Type.Object({
  simple: Type.Optional(Type.Integer()),
  block: Type.Optional(Type.Object({
    min: Type.Integer(),
    max: Type.Integer(),
  })),
  above: Type.Optional(Type.Integer()),
  altFixAlt: Type.Optional(Type.Object({
    fix: Type.String(),
    post: Type.Integer(),
    pre: Type.Integer(),
  })),
  vfrOnTop: Type.Optional(Type.Boolean()),
  vfrPlus: Type.Optional(Type.Integer()),
  vfrOnTopPlus: Type.Optional(Type.Integer()),
  vfr: Type.Optional(Type.Boolean()),
}, { $id: "nasAssignedAltitude" });

export type AssignedAltitude<Altitude extends NasAltitude | number = number> =
  ExactlyOne<{
    simple?: Altitude;
    block?: BlockAltitude<Altitude>;
    above?: Altitude;
    altFixAlt?: {
      fix: string;
      post: Altitude;
      pre: Altitude;
    };
    vfrOnTop?: true;
    vfrPlus?: Altitude;
    vfrOnTopPlus?: Altitude;
    vfr?: true;
  }>;

export type CoordinationTimeType =
  | "ESTIMATED"
  | "PROPOSED"
  | "ACTUAL"
  | "FLUSH"
  | "OTHER";
export const etaTimeTypes = [
  "ACTUAL",
  "AIRLINE",
  "EARLY_INTENT",
  "ESTIMATED",
  "METERED",
  "PROPOSED",
  "REROUTE",
  "SCHEDULED",
  "TAXIED",
] as const satisfies string[];
export type EtaTimeType = (typeof etaTimeTypes)[number];
export const etdTimeTypes = [
  "ACTUAL",
  "AIRLINE",
  "CONTROLLED",
  "ESTIMATED",
  "PROPOSED",
  "SCHEDULED",
] as const satisfies string[];
export type EtdTimeType = (typeof etdTimeTypes)[number];

export type Time<TimeType extends string> = {
  timeType: TimeType;
  value: number;
};

export type NasSectorTraversalElement = {
  sequenceNumber: number;
  sectorId: SectorId;
  elapsedEntryTime: number; // seconds after ETD
};

export type NasFixTraversalElement = {
  sequenceNumber: number;
  fix: string;
  elapsedTime: number; // seconds after ETD
};
