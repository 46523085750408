import React, { useRef } from "react";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { freqAreaHeight } from "components/FreqArea";
import { buttonHeight, fontHeight, fontName, functionButtonWidth, gapSize, vscsColorNameMap } from "~/constants";
import type { Container as PixiContainer } from "pixi.js";
import { usePressedListener } from "~/hooks/usePressedListener";
import { auxMessageAreaWidth } from "./AuxMessageArea";

type PttFreqType = "UHF" | "VHF" | "BOTH";

const angleSize = 10;

type EmergencyPttButtonProps = {
  x?: number;
  y?: number;
  freqType: PttFreqType;
};
export const EmergencyPttButton = ({ x = 0, y = 0, freqType }: EmergencyPttButtonProps) => {
  const ref = useRef<PixiContainer>(null);
  const pressed = usePressedListener(ref, false);

  return (
    <Container x={x} y={y + freqAreaHeight} eventMode="static" ref={ref}>
      <Graphics
        draw={(graphics) => {
          graphics.beginFill(pressed ? vscsColorNameMap.blue : vscsColorNameMap.red);
          graphics.drawPolygon([
            angleSize,
            0,
            functionButtonWidth - angleSize,
            0,
            functionButtonWidth,
            angleSize,
            functionButtonWidth,
            buttonHeight - angleSize,
            functionButtonWidth - angleSize,
            buttonHeight,
            angleSize,
            buttonHeight,
            0,
            buttonHeight - angleSize,
            0,
            angleSize,
            angleSize,
            0,
          ]);
          graphics.endFill();
        }}
      />
      {["PTT", freqType].map((line, i) => (
        <BitmapText
          key={i}
          x={angleSize}
          y={fontHeight * (i + 1)}
          text={line}
          style={{ fontName, tint: vscsColorNameMap.white }}
        />
      ))}
    </Container>
  );
};

export const EmergencyPttArea = ({ x = 0, y = 0 }) => {
  return (
    <Container x={x} y={y}>
      <EmergencyPttButton
        x={0}
        freqType="UHF"
      />
      <EmergencyPttButton
        x={functionButtonWidth + gapSize}
        freqType="BOTH"
      />
      <EmergencyPttButton
        x={(functionButtonWidth + gapSize) * 2}
        freqType="VHF"
      />
  </Container>);
}