/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  CpdlcDialogId,
  EramCpdlcDialog,
  EramCpdlcSession,
} from "@poscon/eram-cpdlc";
import type { EramMessage, EramMessageResult } from "./eram/eramMessaging.js";
import type { EramTrack } from "./eram/eramTrack.js";
import type { ControllerSectorId } from "./poscon/positions.js";
import type { FlightplanId } from "./flightplanData/flightplan.js";
import type { AltitudeLimits } from "./eram/altLimits.js";
import type { LsConfig } from "./eram/lsConfig.js";
import type { Coordinate } from "./common/coordinate.js";
import type {
  AclRowField,
  AclSortMethod,
  DepRowField,
  DepSortMethod,
  PlanRowField,
  PostingMode,
  TrialPlan,
} from "./eram/edst.js";
import type { GeomapConfig } from "./geomap/geomapConfig.js";
import type { RangeCenter, SectorGroup } from "./eram/eramConfig.js";
import type {
  EdstOutageMap,
  EramOutageMap,
  SharedOutageMap,
} from "./outage.js";
import type { EdstFpData } from "./eram/edstFpData.js";
import type { Nullable } from "./utils/utility-types.js";
import type {
  ArtccSAA,
  SaaActivation,
  SaaActivationId,
  SaaId,
  SectorSaaDisplay,
} from "./eram/saa.js";
import type { EramConnection } from "./poscon/roles.js";
import { includes } from "./utils/includes.js";
import type {
  EramCoordination,
  EramLocalCoordination,
} from "./eram/eramCoordination.js";
import type { EramFlightplan, PartialFpWithId } from "./eram/eramFlightplan.js";
import type {
  QuicklookedSectorTrack,
  EramSectorTrack,
} from "./eram/eramSectorTrack.js";
import type { SupportedPidOptionalOpts } from "./createNormalPidResponse.js";
import type { LeaderLength } from "./eram/datablock.js";
import { UserId } from "./poscon/user.js";
import { TrackId } from "./target.js";
import { unsafeKeys } from "./utils/utility-functions.js";
import { StringEnum } from "@feathersjs/typebox";

export const artccLetterMap = {
  KZAB: "A",
  KZNY: "N",
  KZDC: "W",
  KZBW: "B",
  KZOB: "C",
  KZJX: "J",
  KZTL: "T",
  KZHU: "H",
  KZMA: "R",
  KZME: "M",
  KZAU: "G",
  KZDV: "D",
  KZMP: "P",
  KZLA: "L",
  KZKC: "K",
  KZID: "I",
  KZOA: "O",
  KZFW: "F",
  KZLC: "U",
  KZSE: "S",
} as const;

export const artccList = unsafeKeys(artccLetterMap);
export type ArtccId = (typeof artccList)[number];
export const artccIdSchema = StringEnum(artccList);

export const artccListWithAll = ["ALL", ...artccList] as const;

export function isArtccId(id: unknown): id is ArtccId {
  return typeof id === "string" && includes(artccList, id);
}

export type ColorName = "yellow" | "grey" | "red" | "green";

export type CrrGroup = {
  name: string;
  coordinate: Coordinate;
  aircraft: TrackId[];
  color: ColorName;
};
export type RouteLine = { coordinates: Coordinate[]; fullRoute: boolean };
export type ConflictPair = [TrackId, TrackId];

export const MAX_ALTIMETER_LIST_LENGTH = 24;

export type MetarEntry = {
  timeMetarRecorded: number;
  metar: Nullable<string>;
  timeAltimeterRecorded: number;
  altimeter: Nullable<string>;
};

// advisory name serves as ID
export type AdvisoryName = string;
export type Advisory = {
  name: AdvisoryName;
  sectorCreatedShort?: string;
  message: string;
  // unix timestamp
  createdAt: number;
  updatedAt: number;
};

export type EramBaseState = {
  lsConfig: LsConfig | null;
  crrGroups: CrrGroup[];
  altitudeLimits: AltitudeLimits;
  fdbLdrLength: LeaderLength;
  selectedMenuButtonPaths: string[];
};

export type EramPrefset = {
  eram: EramBaseState & unknown;
  range: number;
  center: Coordinate;
  metarList: string[];
  altimList: AltimeterListEntry[];
  viewOptions: unknown;
  settings: unknown;
};

export type PidResponse =
  | "ROGER"
  | "STANDBY"
  | "UNA"
  | "UNA/RES"
  | "UNA/TFC"
  | "UNA/WX"
  | "ATC_HAS_REQ"
  | "UPLINK";

export type AltimeterListEntry = { station: string; index: number };

export type SigninError = string;
export type SignoutError = string;

export interface GlobalServerEventMap {
  signin: (cb: (error?: SigninError) => void) => void;
  signout: (cb: (error?: SignoutError) => void) => void;
  getFrd: (coord: Coordinate, callback: (frd: string | null) => void) => void;
  uiAction: (action: unknown) => void;
}

// TODO: split into global events and eram events
export interface EramServerEventMap extends GlobalServerEventMap {
  processEramMessage: (
    message: EramMessage,
    sentFromGraphicalMenu: boolean,
    skipConfirmation: boolean,
    callback: (result: EramMessageResult) => void,
  ) => void;

  updateEdstFp: (fpId: FlightplanId, data: Partial<EdstFpData>) => void;
  deleteEdstFp: (fpId: FlightplanId) => void;
  addTrialPlan: (trialPlan: TrialPlan) => void;
  trialPlanCleanup: () => void;
  removePointout: (
    trackId: TrackId,
    from: ControllerSectorId,
    to: ControllerSectorId,
  ) => void;

  setCfrIsOpen: (isOpen: boolean) => void;
  setDwellLocked: (trackId: TrackId, locked: boolean) => void;
  setLine4Override: (
    trackId: TrackId,
    value: boolean,
    forQuicklookedFdb?: boolean,
  ) => void;
  setFdbLdr: (length: LeaderLength) => void;

  toggleLdb: (trackId: TrackId) => void;
  removeAllCfrAircraft: () => void;
  setCrrColor: (groupName: string, color: ColorName) => void;
  removeCrrGroup: (groupName: string) => void;
  removeAllCrrAircraftInGroup: (groupName: string) => void;

  setAclSortMethod: (method: AclSortMethod, sortSector: boolean) => void;
  toggleAclPostingMode: () => void;
  setDepSortMethod: (method: DepSortMethod) => void;
  toggleDepPostingMode: () => void;

  promptReliefResponse: (accepted: boolean) => void;

  receivePrefsetState: (prefsetState: unknown) => void;
  setAltimStations: (newStations: AltimeterListEntry[]) => void;

  acknowledgeCpdlcDialogStatus: (
    fpId: FlightplanId,
    dialogId: CpdlcDialogId,
    deleteMsgOutDialog?: boolean,
  ) => void;
  deleteCpdlcDialogs: (
    arr: { fpId: FlightplanId; dialogId: CpdlcDialogId }[],
  ) => void;
  cancelUplinkDialog: (fpId: FlightplanId, dialogId: CpdlcDialogId) => void;
  cleanupOpenTocAndRelease: (fpId: FlightplanId) => void;
  sendPidDialogResponse: (
    fpId: FlightplanId,
    dialogId: CpdlcDialogId,
    response: PidResponse,
    opts?: SupportedPidOptionalOpts,
  ) => void;

  setSectorSaaDisplay: (saaId: SaaId, display: SectorSaaDisplay) => void;
  addSaaActivation: (
    saaId: SaaId,
    activation: Omit<SaaActivation, "id">,
  ) => void;
  removeSaaActivation: (saaId: SaaId, activationId: SaaActivationId) => void;
  updateSaaActivation: (
    saaId: SaaId,
    activationId: SaaActivationId,
    d: Partial<SaaActivation>,
  ) => void;
  setSaaForcedOff: (saaId: SaaId, forcedOff: boolean) => void;
}

export const sectorServerEventNames: (keyof EramServerEventMap)[] = [
  "getFrd",
  "updateEdstFp",
  "deleteEdstFp",
  "addTrialPlan",
  "trialPlanCleanup",
  "removePointout",
  "setCfrIsOpen",
  "setDwellLocked",
  "setLine4Override",
  "setFdbLdr",
  "toggleLdb",
  "removeAllCfrAircraft",
  "setCrrColor",
  "removeCrrGroup",
  "removeAllCrrAircraftInGroup",
  "setAclSortMethod",
  "toggleAclPostingMode",
  "setDepSortMethod",
  "toggleDepPostingMode",
  "promptReliefResponse",
  "receivePrefsetState",
  "setSectorSaaDisplay",
  "acknowledgeCpdlcDialogStatus",
  "deleteCpdlcDialogs",
  "cancelUplinkDialog",
  "cleanupOpenTocAndRelease",
  "sendPidDialogResponse",
  "addSaaActivation",
  "removeSaaActivation",
  "updateSaaActivation",
  "setSaaForcedOff",
  "uiAction",
];

export type BufferedEvents =
  | "updateTracks"
  | "updateSectorTracks"
  | "updateCoordination"
  | "updateFlightplans"
  | "addFlightplans";

export type BufferedEramEvents = "updateQuicklookTracks";

export interface GlobalClientEventMap {
  // EramTrack[]
  updateTracks: (buffer: Uint8Array) => void;
  deleteTracks: (trackIds: TrackId[]) => void;
  // EramFlightplan[]
  addFlightplans: (buffer: Uint8Array) => void;
  updateFlightplans: (buffer: Uint8Array) => void;
  deleteFlightplans: (ids: FlightplanId[]) => void;
  // SectorTrack[]
  updateSectorTracks: (buffer: Uint8Array) => void;
  removeSectorTracks: (trackIds: TrackId[]) => void;

  subscribeToUIState: (v: boolean) => void;
  receiveUiState: (state: unknown) => void;
  receiveUiAction: (action: any) => void;

  promptReliefRequest: (userId: UserId, name: string) => void;
  cancelReliefRequest: () => void;
}

export interface BaseClientEventMap extends GlobalClientEventMap {
  receiveConnectionStatus: (status: EramConnection) => void;

  receiveStcaPairs: (pairs: ConflictPair[]) => void;

  updateSuppressedConflictPairs: (pairs: ConflictPair[]) => void;

  // Record<TrackId, EramCoordination & EramLocalCoordination>
  updateCoordination: (buffer: Uint8Array) => void;

  receiveCpdlcSessions: (
    sessions: Record<FlightplanId, EramCpdlcSession>,
  ) => void;
  receiveCpdlcSession: (fpId: FlightplanId, session: EramCpdlcSession) => void;
  receiveCpdlcDialogs: (
    dialogs: Record<FlightplanId, Record<CpdlcDialogId, EramCpdlcDialog>>,
  ) => void;
  receiveCpdlcSessionEligibilityStatusMap: (
    eligibilityStatusMap: Record<
      FlightplanId,
      Pick<EramCpdlcSession, "logicalDataAuthority" | "eligibleSectorId">
    >,
  ) => void;
  terminateCpdlcSessions: (fpIds: FlightplanId[]) => void;
  receiveTerminatedCpdlcSessions: (
    sessions: Record<FlightplanId, EramCpdlcSession>,
  ) => void;
  deleteCpdlcSessions: (fpIds: FlightplanId[]) => void;

  receiveAdvisory: (advisory: Advisory) => void;
  receiveAdvisories: (advisories: Advisory[]) => void;
  removeAdvisories: (names: AdvisoryName[]) => void;

  receiveAltimeterList: (altimeterList: AltimeterListEntry[]) => void;
  receiveMetarList: (metarList: string[]) => void;
  receiveMetarEntryMap: (metarEntryMap: Record<string, MetarEntry>) => void;

  receiveSaaStatusMap: (saaStatusMap: Record<SaaId, ArtccSAA>) => void;
  receiveSaaStatus: (saaId: SaaId, saa: ArtccSAA) => void;
  receiveSectorSaaDisplayMap: (
    sectorSAADisplayMap: Record<SaaId, SectorSaaDisplay>,
  ) => void;
  receiveSectorSaaDisplay: (saaId: SaaId, display: SectorSaaDisplay) => void;
  receiveSaaActivations: (
    saaId: SaaId,
    activations: SaaActivation[],
    forcedOff: boolean,
  ) => void;

  getUiState: (callback: (state?: EramPrefset) => void) => void;
}

export interface EramClientEventMap extends BaseClientEventMap {
  requestPrefsetState: (callback: (prefset: unknown) => void) => void;
  applyPrefset: (prefset: unknown) => void;
  receivePrefsets: (prefsets: string[]) => void;

  receiveEramOutage: (status: SharedOutageMap & EramOutageMap) => void;

  receiveGeomapConfig: (config: GeomapConfig | null) => void;

  receiveFdbLdr: (length: LeaderLength) => void;
  receiveAltLimits: (limits: AltitudeLimits) => void;
  receiveLsConfig: (lsConfig: LsConfig | null) => void;

  receiveRouteLines: (lines: Record<string, RouteLine>) => void;
  receiveRouteLine: (id: string, line: RouteLine) => void;
  removeRouteLine: (id: string) => void;

  receiveBeaconCodeList: (beaconCodeList: number[]) => void;

  receiveCfrList: (cfrList: FlightplanId[]) => void;

  receiveCrrGroups: (crrGroups: CrrGroup[]) => void;
  receiveCrrGroup: (crrGroup: CrrGroup) => void;
  removeCrrGroup: (name: string) => void;

  // QuicklookedSectorTrack[]
  updateQuicklookTracks: (buffer: Uint8Array) => void;
  removeQuicklookTracks: (tracks: TrackId[]) => void;
  setRangeCenter: (rangeCenter: RangeCenter) => void;
}

export type GIMessage = {
  id: string;
  // unix epoch timestamp
  createdAt: number;
  message: string;
  from: string | null;
  toGroups: SectorGroup[];
  toSectors: ControllerSectorId[];
};

export type RaisableView =
  | "ACL"
  | "DEP"
  | "GPD"
  | "WIND"
  | "CPDLC_ADV"
  | "CPDLC_HIST"
  | "CPDLC_MSGOUT";

export interface EdstClientEventMap extends BaseClientEventMap {
  requestEdstUiState: (callback: (state: any) => void) => void;
  receiveEdstUiState: (state: any) => void;

  receiveEdstOutage: (status: SharedOutageMap & EdstOutageMap) => void;

  receiveGiMessage: (message: GIMessage) => void;
  receiveGiMessages: (messages: GIMessage[]) => void;

  receiveEdstFpMap: (fpMap: Record<FlightplanId, EdstFpData>) => void;
  updateEdstFp: (fpId: FlightplanId, data: EdstFpData) => void;
  deleteEdstFp: (fpId: FlightplanId) => void;

  receiveAclSpaList: (list: FlightplanId[]) => void;
  receiveAclAckList: (list: FlightplanId[]) => void;
  receiveAclUnackList: (list: FlightplanId[]) => void;
  receiveDepSpaList: (list: FlightplanId[]) => void;
  receiveDepAckList: (list: FlightplanId[]) => void;
  receiveDepUnackList: (list: FlightplanId[]) => void;
  receiveGpdAircraftList: (list: FlightplanId[]) => void;

  receiveAclPostingMode: (mode: PostingMode) => void;
  receiveDepPostingMode: (mode: PostingMode) => void;

  receiveAclSortMethod: (method: AclSortMethod, sortSector: boolean) => void;
  receiveDepSortMethod: (method: DepSortMethod) => void;

  receiveAircraftSelect: (
    fpId: FlightplanId,
    field: AclRowField | DepRowField | PlanRowField | null,
  ) => void;

  queryCpdlcHistory: (flid: string) => void;

  receiveTrialPlans: (trialPlans: TrialPlan[]) => void;
  setGpdAircraftList: (list: FlightplanId[]) => void;

  setWindDisplayAltitude: (altitude: number) => void;

  scrollView: (view: "ACL" | "DEP", direction: "UP" | "DOWN") => void;
  raiseView: (view: RaisableView) => void;
  exitAllViews: () => void;
  resetViews: () => void;
}

export interface InflatedBaseClientEventMap
  extends Omit<BaseClientEventMap, BufferedEvents> {
  updateTracks: (tracks: EramTrack[]) => void;

  updateSectorTracks: (sectorTracks: EramSectorTrack[]) => void;

  addFlightplans: (flightplans: EramFlightplan[]) => void;

  updateFlightplans: (flightplans: PartialFpWithId[]) => void;

  updateCoordination: (
    coordinationMap: Record<TrackId, EramCoordination & EramLocalCoordination>,
  ) => void;
}

export interface InflatedEramClientEventMap
  extends InflatedBaseClientEventMap,
  Omit<EramClientEventMap, BufferedEvents | BufferedEramEvents> {
  updateQuicklookTracks: (quicklookTracks: QuicklookedSectorTrack[]) => void;
}

export interface InflatedEdstClientEventMap
  extends InflatedBaseClientEventMap,
  Omit<EdstClientEventMap, BufferedEvents> { }


export type AttempReliefRejectReason = string;

export interface LauncherServerEventMap {
  attemptReliefRequest: (sectorId: ControllerSectorId, role: "Radar" | "Data") => void;
  cancelReliefRequest: () => void;
}

export interface LauncherClientEventMap {
  attemptReliefResponse: (err?: AttempReliefRejectReason) => void;
}

