// ICAO info

import { includes } from "../utils/includes.js";

export const voiceCommCodes = ["H", "U", "V", "Y"] as const satisfies string[];
export const satCommCodes = ["M1", "M2", "M3"] as const satisfies string[];
export const acarsCodes = ["E1", "E2", "E3"] as const satisfies string[];
export const communicationCodes = [
  ...voiceCommCodes,
  ...satCommCodes,
  ...acarsCodes,
];
export type VoiceCommCode = (typeof voiceCommCodes)[number];
export type SatCommCode = (typeof satCommCodes)[number];
export type ACARSCode = (typeof acarsCodes)[number];
export type CommunicationCode = VoiceCommCode | SatCommCode | ACARSCode;

export function isVoiceCommCode(c: string): c is VoiceCommCode {
  return includes(voiceCommCodes, c);
}

export function isSatCommCode(c: string): c is SatCommCode {
  return includes(satCommCodes, c);
}

export function isACARSCode(c: string): c is ACARSCode {
  return includes(acarsCodes, c);
}

export function isCommunicationCode(c: string): c is CommunicationCode {
  return isVoiceCommCode(c) || isSatCommCode(c) || isACARSCode(c);
}

export const approachCodes = ["A", "B", "K", "L", "S"] as const satisfies string[];
export const otherNavigationCodes = [
  "N",
  "Z",
  "W",
  "D",
  "G",
  "T",
  "O",
  "F",
  "I",
  "R",
  "C",
  "X",
] as const satisfies string[];
export const navigationCodes = [...approachCodes, ...otherNavigationCodes];
export type ApproachCode = (typeof approachCodes)[number];
export type OtherNavigationCode = (typeof otherNavigationCodes)[number];
export type NavigationCode = ApproachCode | OtherNavigationCode;

export function isApproachCode(c: string): c is ApproachCode {
  return includes(approachCodes, c);
}

export function isOtherNavigationCode(c: string): c is OtherNavigationCode {
  return includes(otherNavigationCodes, c);
}

export function isNavigationCode(c: string): c is NavigationCode {
  return isApproachCode(c) || isOtherNavigationCode(c);
}

export const transponderCodes = [
  "N",
  "A",
  "C",
  "S",
  "P",
  "I",
  "X",
  "E",
  "H",
  "L",
] as const satisfies string[];
export const adsbCodes = ["B1", "B2", "U1", "U2", "V1", "V2"] as const satisfies string[];
export const adscCodes = ["D1", "G1"] as const satisfies string[];
export const surveillanceCodes = [
  ...transponderCodes,
  ...adsbCodes,
  ...adscCodes,
];
export type TransponderCode = (typeof transponderCodes)[number];
export type AdsbCode = (typeof adsbCodes)[number];
export type AdscCode = (typeof adscCodes)[number];
export type SurveillanceCode = TransponderCode | AdsbCode | AdscCode;

export function isTransponderCode(c: string): c is TransponderCode {
  return includes(transponderCodes, c);
}

export function isAdsbCode(c: string): c is AdsbCode {
  return includes(adsbCodes, c);
}

export function isAdscCode(c: string): c is AdscCode {
  return includes(adscCodes, c);
}

export function isSurveillanceCode(c: string): c is SurveillanceCode {
  return isTransponderCode(c) || isAdsbCode(c) || isAdscCode(c);
}

export const oceanicCodes = ["A1", "L1"] as const;
export const rnav5Codes = ["B1", "B2", "B3", "B4", "B5"] as const;
export const rnav2Codes = ["C1", "C2", "C4"] as const;
export const rnav1Codes = ["D1", "D2", "D4"] as const;
export const rnp1Codes = ["O1", "O2"] as const;
export const rnpApproachCodes = ["S1", "S2"] as const;
export const rnpArApproachCodes = ["T1"] as const;
export const addnPbnCodes = ["Z1", "Z2", "Z5", "R1", "P1", "M1", "M2"] as const;
export const pbnCodes = [
  ...oceanicCodes,
  ...rnav5Codes,
  ...rnav2Codes,
  ...rnav1Codes,
  ...rnpApproachCodes,
  ...rnpArApproachCodes,
  ...addnPbnCodes,
];
export type OceanicCode = (typeof oceanicCodes)[number];
export type RNAV5Code = (typeof rnav5Codes)[number];
export type RNAV2Code = (typeof rnav2Codes)[number];
export type RNAV1Code = (typeof rnav1Codes)[number];
export type RNP1Code = (typeof rnp1Codes)[number];
export type RNPApproachCode = (typeof rnpApproachCodes)[number];
export type RNP_AR_ApproachCode = (typeof rnpArApproachCodes)[number];
export type AddnPbnCode = (typeof addnPbnCodes)[number];
export type PbnCode =
  | OceanicCode
  | RNAV1Code
  | RNAV2Code
  | RNAV5Code
  | RNP1Code
  | RNPApproachCode
  | RNP_AR_ApproachCode
  | AddnPbnCode;

export function isOceanicCode(c: string): c is OceanicCode {
  return includes(oceanicCodes, c);
}

export function isRNAV5Code(c: string): c is RNAV5Code {
  return includes(rnav5Codes, c);
}

export function isRNAV2Code(c: string): c is RNAV2Code {
  return includes(rnav2Codes, c);
}

export function isRNAV1Code(c: string): c is RNAV1Code {
  return includes(rnav1Codes, c);
}

export function isRNP1Code(c: string): c is RNP1Code {
  return includes(rnp1Codes, c);
}

export function isRNPApproachCode(c: string): c is RNPApproachCode {
  return includes(rnpApproachCodes, c);
}

export function isRNP_AR_ApproachCode(c: string): c is RNP_AR_ApproachCode {
  return includes(rnpArApproachCodes, c);
}

export function isAddnPbnCode(c: string): c is AddnPbnCode {
  return includes(addnPbnCodes, c);
}

export function isPbnCode(c: string): c is PbnCode {
  return (
    isOceanicCode(c) ||
    isRNAV1Code(c) ||
    isRNAV2Code(c) ||
    isRNAV5Code(c) ||
    isRNP1Code(c) ||
    isRNPApproachCode(c) ||
    isRNP_AR_ApproachCode(c) ||
    isAddnPbnCode(c)
  );
}

export const datalinkCodes = [
  "J1",
  "J2",
  "J3",
  "J4",
  "J5",
  "J6",
  "J7",
] as const satisfies string[];
export type DatalinkCode = (typeof datalinkCodes)[number];

export function isDatalinkCode(c: string): c is DatalinkCode {
  return includes(datalinkCodes, c);
}

export function extractCodes<T extends string>(
  str: string,
  codes: T[],
): T[] | null {
  const isValidInput = new RegExp(`^(${codes.join("|")})+$`).test(str);
  if (!isValidInput) {
    return null;
  }
  return codes.reduce<T[]>((acc, code) => {
    const matches = str.match(new RegExp(code, "g")) as T[] | null;
    acc.push(...(matches ?? []));
    return acc;
  }, []);
}
