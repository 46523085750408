import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { Nullable } from "@poscon/shared-types";
import type { RootState } from "~redux/store";
import { VikMessage } from "~/vikMessage";

export type VscsPanel = 1 | 2;

// headset or loudspeaker
export type SpeakerMode = "HS" | "LS";

export type AirGroundPositionState = {
  speakerMode: SpeakerMode;
  rx: boolean;
  buecSelected: boolean;
  xmtrStandbySelected: boolean;
  rxStanbySelected: boolean;
  divAlgoSelected: boolean;
  xcplSelected: boolean;
  remMute: boolean;
};

export const agButtonIds = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
] as const;

export type AGPositionId = (typeof agButtonIds)[number];

type AirGroundState = Record<AGPositionId, AirGroundPositionState>;

export const vscsPages = [
  "A/G_1",
  "A/G_2",
  "A/G_STATUS",
  "G/G_1",
  "G/G_2",
  "UTIL",
] as const;

export type VscsStatusMessageType = "error" | "warning" | "system";

export type VscsStatusMessage = {
  type: VscsStatusMessageType;
  msg: string;
};

export type VscsPage = typeof vscsPages[number];

export type Frequency = number;

export type AirGroundModifier = "ALT_SCRN" | "MAIN_SBY" | "BUEC" | "DIV_ALGO" | "XCPL" | "REM_MUTE";

export type Squelch = {
  rx: boolean;
  tx: boolean;
};

type VscsState = {
  page1: VscsPage;
  page2: VscsPage;
  freqRxIdMap: Record<Frequency, AGPositionId>;
  airGroundModifier1: Nullable<AirGroundModifier>;
  airGroundModifier2: Nullable<AirGroundModifier>;
  showFuncAlt1: boolean;
  showFuncAlt2: boolean;
  statusMsg1: Nullable<VscsStatusMessage>;
  statusMsg2: Nullable<VscsStatusMessage>;
  auxMsg1: string;
  auxMsg2: string;
  airGround: AirGroundState;
  squelchMap: Record<AGPositionId, Squelch>;
  vikStatus: Nullable<VikMessage>;
  vikInput: string;
};

const defaultState: AirGroundPositionState = {
  speakerMode: "HS",
  rx: false,
  buecSelected: false,
  xmtrStandbySelected: false,
  rxStanbySelected: false,
  divAlgoSelected: false,
  xcplSelected: false,
  remMute: false,
};

const initialState: VscsState = {
  page1: "A/G_1",
  page2: "A/G_1",
  freqRxIdMap: {},
  airGroundModifier1: null,
  airGroundModifier2: null,
  showFuncAlt1: false,
  showFuncAlt2: false,
  statusMsg1: null,
  statusMsg2: null,
  auxMsg1: "",
  auxMsg2: "",
  airGround: Object.fromEntries(
    agButtonIds.map((i) => [i, { ...defaultState }]),
  ) as AirGroundState,
  squelchMap: Object.fromEntries(
    agButtonIds.map((i) => [i, { rx: false, tx: false }]),
  ) as Record<AGPositionId, Squelch>,
  vikStatus: null,
  vikInput: "",
};

export const vscsSlice = createSlice({
  name: "vscs",
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<{ page: VscsPage, panel: VscsPanel }>) {
      const { page, panel } = action.payload;
      state[`page${panel}`] = page;
    },
    setAirGroundModifier(state, action: PayloadAction<{ modifier: Nullable<AirGroundModifier>, panel: VscsPanel }>) {
      const { modifier, panel } = action.payload;
      state[`airGroundModifier${panel}`] = modifier;
    },
    setAirGroundFreqXmtr(
      state,
      action: PayloadAction<{
        freq: Frequency;
        buttonId: Nullable<AGPositionId>;
      }>,
    ) {
      const { freq, buttonId } = action.payload;
      if (buttonId !== null) {
        state.freqRxIdMap[freq] = buttonId;
      } else {
        delete state.freqRxIdMap[freq];
      }
    },
    updateAirGroundPositionState(state, action: PayloadAction<{ id: AGPositionId, v: AirGroundPositionState }>) {
      const { id, v } = action.payload;
      state.airGround[id] = v;
    },
    setPositionRx(state, action: PayloadAction<{ id: AGPositionId; value: boolean }>) {
      const { id, value } = action.payload;
      state.airGround[id].rx = value;
    },
    toggleShowFuncAlt(state, action: PayloadAction<VscsPanel>) {
      const panel = action.payload;
      state[`showFuncAlt${panel}`] = !state[`showFuncAlt${panel}`];
    },
    setStatusMsg(state, action: PayloadAction<{ msg: Nullable<VscsStatusMessage>, panel: VscsPanel }>) {
      const { msg, panel } = action.payload;
      state[`statusMsg${panel}`] = msg;
    },
    updateSquelch(state, action: PayloadAction<{ id: AGPositionId; value: Squelch }>) {
      const { id, value } = action.payload;
      state.squelchMap[id] = value;
    },
    setVikReady(state, action: PayloadAction<boolean>) {
      state.vikStatus = action.payload ? "READY" : null;
      state.vikInput = "";
    },
    setVikStatus(state, action: PayloadAction<VikMessage | null>) {
      state.vikStatus = action.payload;
    },
    setVikInput(state, action: PayloadAction<string>) {
      if (state.vikStatus !== null) {
        state.vikInput = action.payload;
      }
    },
  },
  selectors: {
    selectAirGroundPositionState: (state) => state.airGround,
    selectPage: (state, panel: VscsPanel) => state[`page${panel}`],
    selectAirGroundModifier: (state, panel: VscsPanel) => state[`airGroundModifier${panel}`],
    selectFreqRxIdMap: (state) => state.freqRxIdMap,
    selectShowFuncAlt: (state, panel: VscsPanel) => state[`showFuncAlt${panel}`],
    selectStatusMsg: (state, panel: VscsPanel) => state[`statusMsg${panel}`],
    selectSquelch: (state, buttonId: AGPositionId) => state.squelchMap[buttonId],
    selectVikReady: (state) => state.vikStatus === "READY",
    selectVikInput: (state) => state.vikInput,
    selectVikStatus: (state) => state.vikStatus,
  },
});

export const vscsReducer = vscsSlice.reducer;

export const {
  setAirGroundFreqXmtr,
  setPositionRx,
  updateAirGroundPositionState,
  setPage,
  setAirGroundModifier,
  toggleShowFuncAlt,
  setStatusMsg,
  updateSquelch,
  setVikReady,
  setVikStatus,
  setVikInput,
} = vscsSlice.actions;

const selectors = vscsSlice.getSelectors((state: RootState) => state.vscs);

export const {
  selectAirGroundPositionState,
  selectPage,
  selectAirGroundModifier,
  selectFreqRxIdMap,
  selectShowFuncAlt,
  selectStatusMsg,
  selectSquelch,
  selectVikReady,
  selectVikInput,
  selectVikStatus,
} = selectors;
