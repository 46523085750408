import { Container } from "@pixi/react";
import { AlternateFunctionButton, ModifierButton, ScrnAltButton } from "./FunctionButton";
import { vscsColorNameMap, functionButtonWidth, gapSize, functionButtonXMapList } from "~/constants";
import { totalButtonHeight } from "./FrequencyButton";
import { useRootDispatch, useRootSelector } from "~/redux/hooks";
import {
    selectAirGroundModifier,
  selectPage,
  selectShowFuncAlt,
  setAirGroundModifier,
  setPage,
  setVikReady,
  toggleShowFuncAlt,
  VscsPage,
  vscsPages
} from "~/redux/slices/vscsSlice";
import { useVscsPanel } from "~/contexts/vscsPanelContext";
import { ComponentType } from "react";

const AgFooter = () => {
  const dispatch = useRootDispatch();
  const panel = useVscsPanel();
  const page = useRootSelector((state) => selectPage(state, panel));
  const showFuncAlt = useRootSelector((state) => selectShowFuncAlt(state, panel));
  return <>
    <AlternateFunctionButton
      x={functionButtonXMapList[1]}
      text={"FUNC\nALT"}
      headerText={showFuncAlt ? "ALT" : "PRI"}
      onpointerdown={() => dispatch(toggleShowFuncAlt(panel))}
    />
    <AlternateFunctionButton
      x={functionButtonXMapList[2]}
      text={"A/G\nALT"}
      onpointerdown={() => {
        dispatch(setPage({ panel, page: page === "A/G_1" ? "A/G_2" : "A/G_1"}));
      }}
    />
    <AlternateFunctionButton
      x={functionButtonXMapList[3]}
      text={"AUTO\nVOICE\nROUTE"}
      bgTint={vscsColorNameMap.green}
    />
    <ModifierButton
      x={functionButtonXMapList[4]}
      modifier="MAIN_SBY"
      text={"MAIN\nSTBY"}
    />
    <ModifierButton
      x={functionButtonXMapList[5]}
      modifier="BUEC"
      text="BUEC"
    />
    <ModifierButton
      x={functionButtonXMapList[6]}
      modifier="XCPL"
      text="XCPL"
    />
    <ModifierButton
      x={functionButtonXMapList[7]}
      modifier={showFuncAlt ? "DIV_ALGO" : "REM_MUTE"}
      text={showFuncAlt ? "DIV\nALGO" : "REM\nMUTE"}
    />
    <AlternateFunctionButton
      x={functionButtonXMapList[8]}
      text={showFuncAlt ? "TEST" : "FTS\nMON"}
      bgTint={vscsColorNameMap.grey}
    />
  </>
}

const GgFooter = () => {
  const dispatch = useRootDispatch();
  const panel = useVscsPanel();
  const page = useRootSelector((state) => selectPage(state, panel));
  const showFuncAlt = useRootSelector((state) => selectShowFuncAlt(state, panel));
  return <>
    <AlternateFunctionButton
      x={functionButtonXMapList[1]}
      text={"FUNC\nALT"}
      headerText={showFuncAlt ? "ALT" : "PRI"}
      onpointerdown={() => dispatch(toggleShowFuncAlt(panel))}
    />
    <AlternateFunctionButton
      x={functionButtonXMapList[2]}
      text={"G/G\nALT"}
      onpointerdown={() => {
        dispatch(setPage({ panel, page: page === "G/G_1" ? "G/G_2" : "G/G_1"}));
      }}
    />
    <AlternateFunctionButton
      x={functionButtonXMapList[3]}
      text={"PSN\nREL"}
    />
    <AlternateFunctionButton
      x={functionButtonXMapList[4]}
      text={"G/G"}
      speakerMode="LS"
    />
    <AlternateFunctionButton
      x={functionButtonXMapList[5]}
      text="OVR"
      speakerMode="LS"
    />
    <AlternateFunctionButton
      x={functionButtonXMapList[6]}
      textX={showFuncAlt ? 0 : undefined}
      text={showFuncAlt ? "HOLLER\nON/OFF" : "CALL\nFWD"}
    />
    <AlternateFunctionButton
      x={functionButtonXMapList[7]}
      width={functionButtonWidth * 2 + gapSize}
      textX={(functionButtonWidth * 2 + gapSize) / 2}
      textAnchor={[0.5, 0]}
      onpointerdown={() => dispatch(setVikReady(false))}
      text="RLS"
    />
  </>
}

const AltFooter = () => {
  const dispatch = useRootDispatch();
  const panel = useVscsPanel();
  const showFuncAlt = useRootSelector((state) => selectShowFuncAlt(state, panel));
  return <>
    {vscsPages.map((p, i) => {
      return <AlternateFunctionButton
        key={p}
        onpointerdown={() => {
          dispatch(setPage({ page: p, panel }));
          dispatch(setAirGroundModifier({ modifier: null, panel }));
        }}
        x={functionButtonXMapList[i + 1]}
        textX={functionButtonWidth/  2}
        textAnchor={[0.5, 0]}
        text={p.split("_").join("\n")}
        headerText={i === 0 ? (showFuncAlt ? "ALT" : "PRI") : undefined}
        bgTint={vscsColorNameMap.violet}
      />
    })}
  </>;
};

const footerComponentMap: Record<VscsPage, ComponentType | null> = {
  "A/G_1": AgFooter,
  "A/G_2": AgFooter,
  "A/G_STATUS": null,
  "G/G_1": GgFooter,
  "G/G_2": GgFooter,
  UTIL: null
}

export const Footer = () => {
  const panel = useVscsPanel();
  const page = useRootSelector((state) => selectPage(state, panel));
  const modifier = useRootSelector((state) => selectAirGroundModifier(state, panel));

  const FooterComponent = footerComponentMap[page];

  return (
    <Container y={totalButtonHeight * 5}>
        <ScrnAltButton />
        {modifier === "ALT_SCRN" ? <AltFooter /> : FooterComponent && <FooterComponent />}
      </Container>
  );
}
