const audioCtx = new AudioContext();

export const horizFreqs = [697, 770, 852, 941];
export const vertFreqs = [1209, 1336, 1477, 1633];

class ToneManager {
  private oscillators: OscillatorNode[] = [];

  startTone(freqs: number[]) {
    this.stopTone();
    
    const oscillators = freqs.map((f) => {
      const osc = audioCtx.createOscillator();
      osc.frequency.value = f;
      return osc;
    });

    for (const osc of oscillators) {
      osc.connect(audioCtx.destination);
      osc.start();
    }

    this.oscillators = oscillators;
  }

  stopTone() {
    for (const osc of this.oscillators) {
      osc.stop();
      osc.disconnect();
    }
    this.oscillators = [];
  }
}

export const toneManager = new ToneManager();
