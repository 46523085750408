import { SpeakerMode } from "~redux/slices/vscsSlice";

export const XCPL_DOWN_ARROW = `\u0100`;
export const XCPL_UP_ARROW = `\u0101`;

export const SPEAKER_ICON = `\u0001`;
export const HEADPHONES_ICON = `\u0002`;
export const STANDBY_S = `\u0003`;
export const DIV_ALCO_ICON = `\u0004`;
export const ERROR_ICON = `\u0005`;

export const speakerModeIconMap: Record<SpeakerMode, string> = {
  HS: HEADPHONES_ICON,
  LS: SPEAKER_ICON,
};
