import React from "react";
import { BitmapText, Container, Graphics } from "@pixi/react";
import type { WindowPosition } from "@poscon/shared-frontend";
import {
  buttonHeight,
  fontHeight,
  fontName,
  fontWidth,
  freqButtonGapSize,
  functionButtonWidth,
  gapSize,
  vscsColorNameMap,
} from "~/constants";
import { FunctionButton } from "components/FunctionButton";
import { FreqArea, freqAreaHeight, freqButtonWidth } from "components/FreqArea";
import type {
  AGPositionId,
  AirGroundPositionState,
} from "~redux/slices/vscsSlice";
import {
  selectAirGroundModifier,
  selectSquelch,
  setAirGroundFreqXmtr,
  setAirGroundModifier,
  setPositionRx,
  setStatusMsg,
  updateAirGroundPositionState,
} from "~redux/slices/vscsSlice";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { AirGroundPosition } from "~/api/api";
import { XCPL_DOWN_ARROW, XCPL_UP_ARROW } from "~/symbols";
import { useVscsPanel } from "~/contexts/vscsPanelContext";

export const totalButtonHeight = freqAreaHeight + buttonHeight;

export const buttonPosIndexList = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
] as const;
export type ButtonPositionIndex = (typeof buttonPosIndexList)[number];

export const posIndexMap: Record<ButtonPositionIndex, WindowPosition> = {
  0: { x: 0, y: 0 },
  1: { x: gapSize + freqButtonWidth, y: 0 },
  2: { x: gapSize * 2 + freqButtonWidth * 2, y: 0 },
  3: { x: 0, y: totalButtonHeight },
  4: { x: gapSize + freqButtonWidth, y: totalButtonHeight },
  5: { x: gapSize * 2 + freqButtonWidth * 2, y: totalButtonHeight },
  6: { x: 0, y: totalButtonHeight * 2 },
  7: { x: gapSize + freqButtonWidth, y: totalButtonHeight * 2 },
  8: { x: gapSize * 2 + freqButtonWidth * 2, y: totalButtonHeight * 2 },
  9: { x: 0, y: totalButtonHeight * 3 },
  10: { x: gapSize + freqButtonWidth, y: totalButtonHeight * 3 },
  11: { x: gapSize * 2 + freqButtonWidth * 2, y: totalButtonHeight * 3 },
};

export const UnassignedFreqButton = ({
  posIndex,
}: { posIndex: ButtonPositionIndex }) => {
  const pos = posIndexMap[posIndex];
  return (
    <Container x={pos.x} y={pos.y + freqAreaHeight}>
      <Graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.beginFill(vscsColorNameMap.grey);
          graphics.drawRect(0, 0, freqButtonWidth, buttonHeight);
          graphics.endFill();
          graphics.drawRect(15, 15, freqButtonWidth - 30, buttonHeight - 30);
          graphics.lineStyle(1, vscsColorNameMap.black);
          graphics.drawRect(5, 10, freqButtonWidth - 10, buttonHeight - 20);
        }}
      />
    </Container>
  );
};

type FreqButtonProps = {
  id: AGPositionId;
  posIndex: ButtonPositionIndex;
  state: AirGroundPositionState;
  position: AirGroundPosition;
  xmtr: boolean;
  xcplPosButtonId?: AGPositionId;
};

export const UnselectedFreqButton = ({
  id,
  state,
  posIndex,
  position: { vhfFreq, uhfFreq, isEmergFreq, transmitterName, backupTransmitterName },
}: FreqButtonProps) => {
  const dispatch = useRootDispatch();
  const panel = useVscsPanel();
  const modifier = useRootSelector((state) => selectAirGroundModifier(state, panel));
  const squelch = useRootSelector((state) => selectSquelch(state, id));
  const pos = posIndexMap[posIndex];
  const freq = vhfFreq ?? uhfFreq ?? 0;
  return (
    <Container
      x={pos.x}
      y={pos.y + freqAreaHeight}
      eventMode="static"
      onpointerdown={() => {
        if (modifier === "REM_MUTE") {
          dispatch(setStatusMsg({ panel, msg: { type: "error", msg: "REM MUTE DENIED:\nFREQ NOT SELECTED" }}));
          dispatch(setAirGroundModifier({ panel, modifier: null }));
        } else {
          dispatch(setPositionRx({ id, value: true }));
          dispatch(setAirGroundFreqXmtr({ freq, buttonId: id }));
        }
      }}
    >
      <Graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.beginFill(vscsColorNameMap.white)
            .drawRect(0, 0, freqButtonWidth, buttonHeight)
            .endFill();
          if (squelch.rx) {
            graphics.lineStyle(10, vscsColorNameMap.orange);
            graphics.drawRect(5, 10, freqButtonWidth - 10, buttonHeight - 20);
          } else {
            if (state.buecSelected || isEmergFreq) {
              graphics.lineStyle(10, isEmergFreq ? vscsColorNameMap.red : vscsColorNameMap.yellow);
              graphics.drawRect(5, 10, freqButtonWidth - 10, buttonHeight - 20);
            }
            graphics.lineStyle(1, isEmergFreq ? vscsColorNameMap.white : vscsColorNameMap.black);
            graphics.drawRect(5, 10, freqButtonWidth - 10, buttonHeight - 20).endFill();
          }
          
          if (state.remMute) {
            graphics.beginFill(0)
              .drawRect(freqButtonWidth - fontWidth * 4 - 1, buttonHeight / 2 - fontHeight - 1, fontWidth * 2 + 4, fontHeight + 1)
              .endFill();
          }
        }}
      />
      <BitmapText
        x={freqButtonWidth / 2}
        y={buttonHeight / 2 - fontHeight}
        text={freq.toFixed(3)}
        anchor={[0.5, 0]}
        style={{ fontName, tint: isEmergFreq ? vscsColorNameMap.red : vscsColorNameMap.black }}
      />
      <BitmapText
        x={freqButtonWidth / 2}
        y={buttonHeight / 2 + 2}
        text={state.buecSelected && backupTransmitterName ? backupTransmitterName : transmitterName}
        anchor={[0.5, 0]}
        style={{ fontName, tint: isEmergFreq ? vscsColorNameMap.red : vscsColorNameMap.black }}
      />
      {state.remMute && (
        <BitmapText
          x={freqButtonWidth - fontWidth * 4}
          y={buttonHeight / 2 - fontHeight}
          text="RM"
          style={{ fontName, tint: vscsColorNameMap.white }}
        />
      )}
    </Container>
  );
};

export const SelectedFreqButton = ({
  id,
  posIndex,
  xmtr,
  state,
  xcplPosButtonId,
  position: { vhfFreq, uhfFreq, transmitterName, isEmergFreq, backupTransmitterName, isDivAlgo, xcpl, remoteMute },
}: FreqButtonProps) => {
  const dispatch = useRootDispatch();
  const panel = useVscsPanel();
  const modifier = useRootSelector((state) => selectAirGroundModifier(state, panel));
  const squelch = useRootSelector((state) => selectSquelch(state, id));
  const buecModifierSelected = modifier === "BUEC";
  const { speakerMode, buecSelected, xmtrStandbySelected, rxStanbySelected, divAlgoSelected, xcplSelected, rx, remMute } = state;

  const pos = posIndexMap[posIndex];
  const freq = vhfFreq ?? uhfFreq ?? 0;

  const selectedColor = buecSelected ? vscsColorNameMap.yellow : vscsColorNameMap.green;
  const unselectedTint = buecSelected ? vscsColorNameMap.yellow : vscsColorNameMap.white;
  const rxSelectedTint = remMute ? selectedColor : vscsColorNameMap.black;

  const onPointerDown = (btn: "xmtr" | "name" | "rx", onNoModifier: () => void) => {
    if (modifier === "BUEC") {
      if (backupTransmitterName) {
        dispatch(updateAirGroundPositionState({ id, v: { ...state, buecSelected: !state.buecSelected } }));
      } else {
        dispatch(setStatusMsg({ panel, msg: { type: "error", msg: "BUEC DENIED:\nNO BACKUP XMTR" } }));
      }
    } else if (modifier === "DIV_ALGO") {
      if (isDivAlgo) {
        dispatch(updateAirGroundPositionState({ id, v: { ...state, divAlgoSelected: !state.divAlgoSelected } }));
      } else {
        dispatch(setStatusMsg({ panel, msg: { type: "error", msg: "DIV ALGO DENIED:\nFREQ NOT CLASSMARKED" } }));
      }
    } else if (modifier === "XCPL") {
      if (xcpl) {
        dispatch(updateAirGroundPositionState({ id, v: { ...state, xcplSelected: !state.xcplSelected } }));
      } else {
        dispatch(setStatusMsg({ panel, msg: { type: "error", msg: "XCPL DENIED:\nFREQ NOT CLASSMARKED" } }));
      }
    } else if (modifier === "REM_MUTE" && (btn === "rx" || buecSelected)) {
      if (remoteMute) {
        if (buecSelected) {
          dispatch(setStatusMsg({ panel, msg: { type: "error", msg: "REM MUTE DENIED:\nFREQ ON BUEC" } }));
        } else {
          dispatch(updateAirGroundPositionState({ id, v: { ...state, remMute: !state.remMute, xcplSelected: false } }));
          if (xcplSelected && !state.remMute) {
            dispatch(setStatusMsg({ panel, msg: { type: "warning", msg: "REM MUTING A XCPLD\nFREQ NEGATES THE\nXCPL" } }));
          }
        }
      } else {
        dispatch(setStatusMsg({ panel, msg: { type: "error", msg: "REM MUTE DENIED:\nFREQ NOT CLASSMARKED" } }));
      }
    } else if (!modifier) {
      onNoModifier();
    }
    if (modifier) {
      dispatch(setAirGroundModifier({ modifier: null, panel }));
    }
  }

  const xcplDirection = typeof xcplPosButtonId === "number" ? id - xcplPosButtonId : null;

  return (
    <Container x={pos.x} y={pos.y} sortableChildren>
      <FreqArea
        freq={freq}
        isEmergFreq={isEmergFreq}
        highlight={!!(buecModifierSelected && backupTransmitterName) ||
                   (modifier === "DIV_ALGO" && isDivAlgo) || 
                   !!(modifier === "XCPL" && xcpl) ||
                   !!(modifier === "REM_MUTE" && remoteMute)}
      />
      <Graphics
        zIndex={0}
        draw={(graphics) => {
          graphics.clear();
          graphics
            .beginFill(vscsColorNameMap.grey)
            .drawRect(0, freqAreaHeight, freqButtonWidth, buttonHeight)
            .endFill();
        }}
      />
      <Container y={freqAreaHeight} zIndex={1}>
        {xcplSelected && xcplDirection && (
          <>
            <BitmapText
              x={functionButtonWidth - 1}
              y={fontHeight * 2}
              text={xcplDirection < 0 ? XCPL_DOWN_ARROW : XCPL_UP_ARROW}
              style={{ fontName, tint: 0 }}
            />
            <BitmapText
              x={functionButtonWidth * 2 + freqButtonGapSize - 1}
              y={fontHeight * 2}
              text={xcplDirection < 0 ? XCPL_DOWN_ARROW : XCPL_UP_ARROW}
              style={{ fontName, tint: 0 }}
            />
          </>
        )}
        <FunctionButton
          onpointerdown={() => onPointerDown("xmtr", () => dispatch(setAirGroundFreqXmtr({ freq, buttonId: xmtr ? null : id })))}
          bgTint={xmtr ? (squelch.tx ? vscsColorNameMap.orange : selectedColor) : vscsColorNameMap.black}
          tint={xmtr ? vscsColorNameMap.black : unselectedTint}
          stbySelected={xmtrStandbySelected}
          text={`XMTR\n${xmtr ? "ON" : "OFF"}`}
        />
        <FunctionButton
          x={functionButtonWidth + freqButtonGapSize}
          bgTint={rx && !remMute ? selectedColor : vscsColorNameMap.black}
          tint={rx ? rxSelectedTint : unselectedTint}
          onpointerdown={() => onPointerDown(
            "name", 
            () => dispatch(updateAirGroundPositionState({ id, v: { ...state, speakerMode: speakerMode === "HS" ? "LS" : "HS" } }))
          )}
          speakerMode={speakerMode}
          text={buecSelected && backupTransmitterName ? backupTransmitterName : transmitterName}
          bottomText={buecSelected ? "BUEC" : ""}
        />
        <FunctionButton
          onpointerdown={() => onPointerDown("rx", () => dispatch(setPositionRx({ id, value: !rx })))}
          x={(functionButtonWidth + freqButtonGapSize) * 2}
          bgTint={rx && !remMute ? (squelch.rx ? vscsColorNameMap.orange : selectedColor) : vscsColorNameMap.black}
          tint={rx ? rxSelectedTint : unselectedTint}
          text={`RCVR\n${rx ? "ON" : "OFF"}\n${remMute ? "RM" : ""}`}
          stbySelected={rxStanbySelected}
          divAlgoSelected={divAlgoSelected}
        />
      </Container>
    </Container>
  );
};
