import React from "react";
import { buttonHeight, fontHeight, fontName, fontWidth, freqButtonGapSize, functionButtonWidth, gapSize, iconFontName, statusFontName, vscsColorNameMap } from "~/constants";
import { BitmapText, Container, Graphics } from "@pixi/react";
import { freqAreaHeight } from "components/FreqArea";
import { useRootDispatch, useRootSelector } from "~/redux/hooks";
import { selectStatusMsg, setStatusMsg, VscsStatusMessageType } from "~/redux/slices/vscsSlice";
import { ERROR_ICON } from "~/symbols";
import { useVscsPanel } from "~/contexts/vscsPanelContext";

export const statusAreaWidth = functionButtonWidth * 4 + gapSize + freqButtonGapSize * 2;

const iconMap: Record<VscsStatusMessageType, string | null> = {
  system: null,
  error: ERROR_ICON,
  warning: ERROR_ICON,
};

type StatusAreaProps = {
  x?: number;
  y?: number;
};
export const StatusArea = ({ x = 0, y = 0 }: StatusAreaProps) => {
  const dispatch = useRootDispatch();
  const panel = useVscsPanel();
  const status = useRootSelector((state) => selectStatusMsg(state, panel));

  const icon = status ? iconMap[status.type] : null;

  return (
    <Container x={x} y={y + freqAreaHeight}>
      <Graphics
        eventMode={status ? "static" : "none"}
        onpointerdown={() => {
          dispatch(setStatusMsg({ msg: null, panel }));
        }}
        draw={(graphics) => {
          graphics.clear();
          graphics.beginFill(status ? vscsColorNameMap.pink : vscsColorNameMap.grey)
            .drawRect(0, 0, statusAreaWidth, buttonHeight)
            .endFill();
        }}
      />
      {status?.msg && (
        <BitmapText
          x={fontWidth * 3 + 4}
          y={fontHeight}
          eventMode="none"
          text={status.msg}
          style={{ fontName: statusFontName , tint: 0 }}
        />
      )}
      {icon && (
        <BitmapText
          x={0}
          y={buttonHeight / 2}
          anchor={[0, 0.5]}
          eventMode="none"
          text={icon}
          style={{ fontName: iconFontName, tint: 0 }}
        />
      )}
    </Container>
  );
};
