import { selectPage, selectVikReady, setPage, setVikInput, setVikStatus } from "./slices/vscsSlice";
import { RootThunkAction } from "./store";

export function updateVikInputThunk(input: string): RootThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    if (!selectVikReady(state)) {
      return;
    }
    dispatch(setVikInput(input));
    if (input === "*3") {
      const page1 = selectPage(state, 1);
      const page2 = selectPage(state, 2);
      dispatch(setPage({ page: page1, panel: 2 }));
      dispatch(setPage({ page: page2, panel: 1 }));
      dispatch(setVikStatus("DISPLAY_EXCHNG"));
    }
  }
}
