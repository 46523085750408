import { RootStore } from "./store";
import { SquelchXmtrPayload } from "~/commands";
import { updateSquelch } from "./slices/vscsSlice";
import { listen } from "@tauri-apps/api/event";

export function afterStoreInit(store: RootStore) {
  if (window.__TAURI__) {
    listen<SquelchXmtrPayload>("squelch", ({ payload }) => {
      store.dispatch(updateSquelch({ id: payload.btnId, value: { rx: payload.rx, tx: payload.tx }}));
    });
  }
}