import type { Action, ThunkAction } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { vscsApi } from "~/api/api";
import { vscsReducer } from "~redux/slices/vscsSlice";
import { afterStoreInit } from "./afterStoreInit";

export const store = configureStore({
  reducer: {
    vscs: vscsReducer,
    [vscsApi.reducerPath]: vscsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat([vscsApi.middleware]),
});

afterStoreInit(store);

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootStore = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
export type RootThunkAction<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
