/* React-specific entry point that automatically generates
   hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { isString } from "@poscon/shared-types";
import { posconApi } from "@poscon/shared-frontend";
import { useMemo } from "react";
import { AGPositionId } from "~/redux/slices/vscsSlice";
import { env } from "~/env";

const baseUrl = env.VITE_ERAM_SERVER_URL;

const baseQuery = fetchBaseQuery({ baseUrl });

const baseQueryWithToken: typeof baseQuery = async (arg, api, extraOptions) => {
  const url = isString(arg) ? arg : arg.url;
  if (url.includes("access_token=") || (!isString(arg) && arg.params?.token)) {
    return baseQuery(arg, api, extraOptions);
  }
  // we don't have a token, get one
  const token = await posconApi.getAtcAccessToken();
  const definitelyAnObjArg = isString(arg) ? { url } : { ...arg, url };
  return baseQuery(
    {
      ...definitelyAnObjArg,
      params: { ...definitelyAnObjArg.params, access_token: token },
    },
    api,
    extraOptions,
  );
};

type Frequency = number;

export type AirGroundPosition = {
  buttonId: AGPositionId;
  vhfFreq?: Frequency;
  uhfFreq?: Frequency;
  transmitterName: string;
  backupTransmitterName?: string;
  isEmergFreq: boolean;
  isDivAlgo?: boolean;
  remoteMute?: boolean;
  xcpl?: Frequency;
};

export type VscsApiResponse = {
  sectorId: string;
  role: string;
  airGroundPositions: AirGroundPosition[];
};

export type RcagId = string;

export type Rcag = {
  rcagId: RcagId;
  latitude: number;
  longitude: number;
  elevation: number;
}

// Define a service using a base URL and expected endpoints
export const vscsApi = createApi({
  reducerPath: "vscsConfig",
  baseQuery,
  endpoints: (builder) => ({
    vscsConfig: builder.query<VscsApiResponse, [string, string]>({
      query: ([sectorId, role]) => `api/${sectorId.split(".")[0]!}/vscsConfig/${sectorId}/${role}`,
    }),
    rcags: builder.query<Rcag[], string>({
      query: (artccId) => `api/${artccId}/rcags`
    }),
  }),
});

export const { useVscsConfigQuery, useRcagsQuery } = vscsApi;

// for testing
const defaultSectorId = "KZNY.ZNY.86";

const urlParams = new URLSearchParams(window.location.search);

export const useAirGroundPositions = () => {
  const urlSectorId = (urlParams.get("sectorId") ?? defaultSectorId).split(".");
  const sectorId = [...urlSectorId.slice(0, 2), urlSectorId.at(-1)].join(".");
  const role = urlParams.get("role") ?? "Radar";
  const query = useMemo<[string, string]>(() => ([sectorId, role]), [sectorId, role]);
  const { data } = useVscsConfigQuery(query);
  return useMemo(() => data?.airGroundPositions ?? [], [data]);
}

export const useRcags = () => {
  const urlSectorId = urlParams.get("sectorId") ?? defaultSectorId;
  const { data } = useRcagsQuery(urlSectorId.split(".")[0]!);
  return useMemo(() => data ?? [], [data]);
}