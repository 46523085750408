import { Coordinate } from "../common/coordinate.js";
import type { ReplaceString } from "./utility-types.js";

export const unsafeEntries = Object.entries as <T, K extends string>(o: Record<K, T>) => [K, T][];
export const unsafeKeys = Object.keys as <T, K extends string>(o: Record<K, T>) => K[];
export const isEnum =
  <T extends Record<string, string>>(enumObj: T) =>
    (s: string): s is T[keyof T] =>
      Object.values(enumObj).includes(s);

export function assert(value: unknown, message: string): asserts value {
  if (!value) {
    throw new Error(message);
  }
}

export function isString(a: unknown): a is string {
  return typeof a === "string";
}

export function isStringArray(a: Array<unknown>): a is string[] {
  return a.every(isString);
}

export function isNumber(a: unknown): a is number {
  return typeof a === "number";
}

export function isNumberArray(a: Array<unknown>): a is number[] {
  return a.every(isNumber);
}

export const unsafeToLowerCase = <T extends string>(s: T): Lowercase<T> => s.toLowerCase() as Lowercase<T>;
export const unsafeReplace = <T extends string, S extends string, R extends string>(t: T, s: S, r: R) =>
  t.replaceAll(s, r) as ReplaceString<T, S, R>;

// Allows creating a tuple of arbitrary size N
export type Tuple<T, N extends number, R extends T[] = []> = R["length"] extends N ? R : Tuple<T, N, [T, ...R]>;

export function hasExactly<T, N extends number>(array: T[], length: N): array is Tuple<T, N> {
  return array.length === length;
}

// Defines an array with at least N elements
export type HasAtLeast<T, N extends number> = Tuple<T, N> & T[];

export function hasAtLeast<T, N extends number>(array: T[], length: N): array is HasAtLeast<T, N> {
  return array.length >= length;
}

export const mod = (n: number, m: number) => ((n % m) + m) % m;

/**
 *
 * @param date optional date to use, otherwise a new Date object is created and the current time will be returned
 */
export function getUtcMinutesAfterMidnight(date?: Date) {
  const _date = date ?? new Date();
  return _date.getUTCHours() * 60 + _date.getUTCMinutes();
}

export function formatUtcTime(_date: Date | number | string) {
  const date = !(_date instanceof Date) ? new Date(_date) : _date;
  return `${date.getUTCHours().toString().padStart(2, "0")}${date.getUTCMinutes().toString().padStart(2, "0")}`;
}

export function removeUndefined<T extends object>(obj: T, removeNull = false) {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== undefined && (!removeNull || value !== null))) as T;
}

export function sumBy<T>(array: T[], getNumber: (value: T) => number): number {
  return array.reduce((a, b) => a + getNumber(b), 0);
}

export function isCoordinate(a: unknown): a is Coordinate {
  return Array.isArray(a) && a.length === 2 && a.every((e) => typeof e === "number");
}
