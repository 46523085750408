export const vscsColorNameMap = {
  black: 0,
  blue: 0x0000ff,
  cyan: 0x00ffff,
  darkGrey: 0x3a3a3a,
  green: 0x22b14d,
  grey: 0xadadad,
  lightGrey: 0xcacaca,
  orange: 0xff7d27,
  red: 0xee1111,
  white: 0xeeeeee,
  yellow: 0xffff00,
  violet: 0x8080ff,
  pink: 0xffbacf,
} as const;

export type VscsButtonStatus =
  | "off"
  | "unavailable"
  | "available"
  | "touched"
  | "active"
  | "activated"
  | "ringing"
  | "hold";

export const fontName = "vscs";
export const fontDim = { fontWidth: 13, fontHeight: 16 };
export const { fontWidth, fontHeight } = fontDim;
export const iconFontName = "vscsIcons";
export const statusFontName = "vscsSmall";
export const pageNumbersFontName = "vscsSpecialNumbers";

export const gapSize = 13;
export const freqButtonGapSize = 12;
export const buttonHeight = fontHeight * 5 + 4;
export const functionButtonWidth = fontWidth * 6;

export const functionButtonXMapList = [
  0,
  functionButtonWidth + freqButtonGapSize,
  (functionButtonWidth + freqButtonGapSize) * 2,
  functionButtonWidth * 3 + freqButtonGapSize * 2 + gapSize,
  functionButtonWidth * 4 + freqButtonGapSize * 3 + gapSize,
  functionButtonWidth * 5 + freqButtonGapSize * 4 + gapSize,
  functionButtonWidth * 6 + freqButtonGapSize * 4 + gapSize * 2,
  functionButtonWidth * 7 + freqButtonGapSize * 5 + gapSize * 2,
  functionButtonWidth * 8 + freqButtonGapSize * 6 + gapSize * 2
];

const urlParams = new URLSearchParams(window.location.search);
const numPanelsStr = urlParams.get("panels") ?? "";
export const numPanels = (/^[12]$/.test(numPanelsStr) ? parseInt(numPanelsStr) : 1) as 1 | 2;