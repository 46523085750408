import React, { useEffect, useRef, useState } from "react";
import {
  buttonHeight,
  fontHeight,
  fontName,
  fontWidth,
  functionButtonWidth,
  iconFontName,
  pageNumbersFontName,
  vscsColorNameMap,
} from "~/constants";
import { BitmapText, Container, Graphics } from "@pixi/react";
import type { ColorSource, Container as PixiContainer } from "pixi.js";
import { freqAreaHeight } from "components/FreqArea";
import { DIV_ALCO_ICON, STANDBY_S } from "~/symbols";
import { speakerModeIconMap } from "~/symbols";
import { AirGroundModifier,
  selectAirGroundModifier,
  selectPage,
  setAirGroundModifier,
  VscsPage,
  type SpeakerMode
} from "~redux/slices/vscsSlice";
import { usePressedListener } from "~/hooks/usePressedListener";
import { useRootDispatch, useRootSelector } from "~/redux/hooks";
import { useInterval } from "usehooks-ts";
import { useVscsPanel } from "~/contexts/vscsPanelContext";

type FunctionButtonProps = {
  x?: number;
  y?: number;
  textAnchor?: [number, number];
  textX?: number;
  textY?: number;
  width?: number;
  bgTint: ColorSource;
  tint: ColorSource;
  text: string;
  bottomText?: string;
  speakerMode?: SpeakerMode;
  divAlgoSelected?: boolean;
  stbySelected?: boolean;
  onpointerdown?: () => void;
};
export const FunctionButton = ({
  x = 0,
  y = 0,
  width = functionButtonWidth,
  textX = functionButtonWidth / 2,
  textY = 0,
  textAnchor = [0.5, 0],
  bgTint,
  tint: _tint,
  text,
  bottomText,
  onpointerdown,
  speakerMode,
  stbySelected,
  divAlgoSelected,
}: FunctionButtonProps) => {
  const ref = useRef<PixiContainer>(null);
  const pressed = usePressedListener(ref);

  const bgColor = pressed ? vscsColorNameMap.blue : bgTint;
  const tint = pressed ? vscsColorNameMap.white : _tint;
  return (
    <Container x={x} y={y} eventMode="static" ref={ref}>
      <Graphics
        eventMode="static"
        onpointerdown={onpointerdown}
        draw={(graphics) => {
          graphics.beginFill(bgColor);
          graphics.drawRect(0, 0, width, buttonHeight);
          graphics.endFill();
        }}
      />
      {text.split("\n").map((line, i) => (
        <BitmapText
          eventMode="none"
          key={i}
          x={textX}
          y={textY + fontHeight * i}
          text={line}
          anchor={textAnchor}
          tint={tint}
          style={{ fontName, tint }}
        />
      ))}
      {speakerMode && (
        <BitmapText
          x={width / 2}
          y={fontHeight * 2}
          text={speakerModeIconMap[speakerMode]}
          anchor={[0.5, 0]}
          tint={tint}
          eventMode="none"
          style={{ fontName: iconFontName, tint }}
        />
      )}
      {bottomText && (
        <BitmapText
          x={width / 2}
          y={buttonHeight}
          text={bottomText}
          anchor={[0.5, 1]}
          tint={tint}
          eventMode="none"
          style={{ fontName, tint }}
        />
      )}
      {stbySelected && (
        <BitmapText
          x={10}
          y={buttonHeight}
          text={STANDBY_S}
          anchor={[0, 1]}
          tint={tint}
          eventMode="none"
          style={{ fontName: iconFontName, tint }}
        />
      )}
      {divAlgoSelected && (
        <BitmapText
          x={width}
          y={buttonHeight}
          text={DIV_ALCO_ICON}
          anchor={[1, 1]}
          tint={tint}
          eventMode="none"
          style={{ fontName: iconFontName, tint }}
        />
      )}
    </Container>
  );
};

type AlternateFunctionButtonProps = {
  x?: number;
  y?: number;
  textAnchor?: [number, number]
  textX?: number;
  textY?: number;
  width?: number;
  bgTint?: ColorSource;
  tint?: ColorSource;
  text: string;
  headerText?: string;
  onpointerdown?: () => void;
  speakerMode?: SpeakerMode;
};

export const AlternateFunctionButton = ({
  x = 0,
  y = 0,
  textAnchor = [0, 0],
  textX = fontWidth,
  textY = fontHeight,
  width = functionButtonWidth,
  bgTint = vscsColorNameMap.cyan,
  tint = vscsColorNameMap.black,
  text,
  speakerMode,
  headerText,
  onpointerdown,
}: AlternateFunctionButtonProps) => {

  return (
    <Container x={x} y={y} eventMode={onpointerdown ? "static" : "none"}>
      {headerText && <>
        <Graphics
          draw={(graphics) => {
            graphics.clear();
            graphics.beginFill(vscsColorNameMap.white);
            graphics.drawRect(0, 0, width, freqAreaHeight);
            graphics.endFill();
          }}
        />
        <BitmapText text={headerText} x={width / 2} anchor={[0.5, 0]} style={{ tint: vscsColorNameMap.black, fontName }} />
      </>}
      <FunctionButton 
        x={0}
        y={freqAreaHeight}
        textAnchor={textAnchor}
        bgTint={bgTint}
        tint={tint}
        textX={textX}
        textY={textY}
        width={width}
        text={text}
        onpointerdown={onpointerdown}
        speakerMode={speakerMode}
      />
   </Container>
  );
};

type ModifierButtonProps = {
  x?: number;
  y?: number;
  color?: ColorSource;
  toggleColor?: ColorSource;
  tint?: ColorSource;
  text: string;
  modifier: AirGroundModifier;
  children?: React.ReactNode;
};

export const ModifierButton = ({
  x = 0,
  y = 0,
  color = vscsColorNameMap.cyan,
  toggleColor = vscsColorNameMap.green,
  tint = 0,
  modifier,
  text,
  children,
}: ModifierButtonProps) => {
  const dispatch = useRootDispatch();
  const panel = useVscsPanel();
  const modifierState = useRootSelector((state) => selectAirGroundModifier(state, panel));
  const [blinkColor, setBlinkColor] = useState(false);

  const selected = modifierState === modifier;

  useInterval(() => {
    setBlinkColor((prev) => !prev);
  }, selected ? 500 : null);

  useInterval(() => {
    dispatch(setAirGroundModifier({ modifier: null, panel }));
  }, selected ? 15e3 : null);

  useEffect(() => {
    if (!selected) {
      setBlinkColor(false);
    }
  }, [selected]);
  
  const bgColor = selected && blinkColor ? toggleColor : color;

  return (
    <Container x={x} y={y}>
      <FunctionButton
        onpointerdown={() => {
          dispatch(setAirGroundModifier({ modifier: !selected ? modifier : null, panel }));
        }}
        x={0}
        y={freqAreaHeight}
        textAnchor={[0, 0]}
        textX={fontWidth}
        textY={fontHeight}
        bgTint={bgColor}
        tint={tint}
        text={text}
      />
      {children}
    </Container>
  );
}

const pageTextMap: Record<VscsPage, string> = {
  "A/G_1":  `A/G`,
  "A/G_2": `A/G`,
  "A/G_STATUS": "STATUS",
  "G/G_1": `G/G`,
  "G/G_2": `G/G`,
  UTIL: "UTIL"
}

const Page = () => {
  const panel = useVscsPanel();
  const page = useRootSelector((state) => selectPage(state, panel));
  const num = ["1", "2"].includes(page.at(-1)!) ? page.at(-1)! : null;
  return (
    <>
      <Graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.beginFill(vscsColorNameMap.white)
            .drawRect(0, 0, functionButtonWidth, freqAreaHeight)
            .endFill();
        }}
      />
      <BitmapText text={pageTextMap[page]} x={page === "A/G_STATUS" ? 0 : fontWidth} style={{ tint: vscsColorNameMap.black, fontName }} />
      {num && <BitmapText text={num} x={fontWidth * 4 + 6} style={{ tint: vscsColorNameMap.black, fontName: pageNumbersFontName }} />}
    </>
  );
}

export const ScrnAltButton = ({ x = 0, y = 0 }) => { 
  return (
    <Container x={x} y={y}>
      <Page />
      <ModifierButton
        modifier="ALT_SCRN"
        text={"SCRN\nALT"}
      />
    </Container>
  );
};
